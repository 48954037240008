@if (!url) {
  <button matRipple
          [matRippleDisabled]="disabled || clazz === 'btn-type-19'"
          class='scp-btn'
          [type]='action'
          [class]='clazz'
          [class.btn-margin-top]='hasMarginTop'
          [class.btn-icon-and-text]='imgSrc && text'
          [class.scp-right]="align === 'right'"
          [class.btn-icon]='imgSrc && !text'
          [class.btn-mobile]='mobileHeight'
          [style.height.px]='height'
          [style.width.px]='width'
          [disabled]='disabled || isDispatching'
          [tabindex]='tabindex'>
    @if (imgSrc) {
      <span>
        <img [src]='imgSrc' alt loading='lazy' width='18' height='18' />
      </span>
    }
    {{ text | translate }}
  </button>
} @else if (url.startsWith('http')) {
  <a [target]='target'
     matRipple
     [matRippleDisabled]="disabled || clazz === 'btn-type-19'"
     class='scp-btn'
     [class]='clazz'
     [class.btn-margin-top]='hasMarginTop'
     [class.btn-icon-and-text]='imgSrc && text'
     [class.scp-right]="align === 'right'"
     [class.btn-mobile]='mobileHeight'
     [style.height.px]='height'
     [style.width.px]='width'
     [href]='url'
     [tabindex]='tabindex'>
    @if (imgSrc) {
      <span>
        <img [src]='imgSrc' alt loading='lazy' width='18' height='18' />
      </span>
    }
    {{ text | translate }}
  </a>
} @else {
  <a matRipple
     [matRippleDisabled]="disabled || clazz === 'btn-type-19'"
     class='scp-btn'
     [class]='clazz'
     [class.btn-margin-top]='hasMarginTop'
     [class.btn-icon-and-text]='imgSrc && text'
     [class.scp-right]="align === 'right'"
     [class.btn-mobile]='mobileHeight'
     [style.height.px]='height'
     [style.width.px]='width'
     [routerLink]='url'
     [tabindex]='tabindex'>
    @if (imgSrc) {
      <span>
        <img [src]='imgSrc' alt loading='lazy' width='18' height='18' />
      </span>
    }
    {{ text | translate }}
  </a>
}
