import { Injectable, OnDestroy } from '@angular/core';

type EntryCallback = (isVisible: boolean) => void;

interface EntryInfo {
  callback: EntryCallback;
  observerInfo: ObserverInfo;
}

interface ObserverInfo {
  config: IntersectionObserverInit;
  count: number;
  observer: IntersectionObserver;
}

@Injectable({ providedIn: 'root' })
export class VisibilityService implements OnDestroy {
  private entryMap = new Map<Element, EntryInfo>();
  private observerInfoList: ObserverInfo[] = [];

  /* istanbul ignore next */
  public ngOnDestroy(): void {
    this.entryMap.forEach((entryInfo, element) => {
      this.unobserve(element);
    });
    this.entryMap.clear();
  }

  public observe(
    element: Element,
    callback: EntryCallback,
    config: Partial<IntersectionObserverInit> = {}
  ): void {
    const threshold = config.threshold || [0];
    const configCopy: IntersectionObserverInit = {
      root: config.root || null,
      rootMargin: config.rootMargin || '0px',
      threshold
    };
    const observerInfo = this.getObserver(configCopy);
    this.entryMap.set(element, {
      callback,
      observerInfo
    });
    observerInfo.observer.observe(element);
    observerInfo.count += 1;
  }

  public unobserve(element: Element): void {
    const entryInfo = this.entryMap.get(element);

    if (entryInfo) {
      this.entryMap.delete(element);
      entryInfo.observerInfo.count -= 1;

      if (entryInfo.observerInfo.count === 0) {
        entryInfo.observerInfo.observer.disconnect();
        this.observerInfoList = this.observerInfoList.filter(
          oi => oi.observer !== entryInfo.observerInfo.observer
        );
      }
    }
  }

  private getObserver(config: IntersectionObserverInit) {
    const configThresholdString = (config.threshold as number[]).join(' ');
    const filteredList = this.observerInfoList.filter(
      oi =>
        oi.config.root === config.root &&
        oi.config.rootMargin === config.rootMargin &&
        (oi.config.threshold as number[]).join(' ') ===
        configThresholdString
    );

    if (filteredList && filteredList.length) {
      return filteredList[0];
    }

    const observer = new IntersectionObserver(toggledEntries => {
      for (const entry of toggledEntries) {
        const entryInfo = this.entryMap.get(entry.target);

        if (entryInfo) {
          entryInfo.callback(entry.isIntersecting);
        }
      }
    }, config);

    const observerInfo = {
      observer,
      config,
      count: 0
    };
    this.observerInfoList.push(observerInfo);

    return observerInfo;
  }
}
