/* istanbul ignore file */

import { Injectable, NgZone } from '@angular/core';
import { debounceTime, observeOn, publish, refCount, take } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';

// requestIdleCallback polyfill
export type Deadline = { didTimeout: boolean, timeRemaining: () => number };
export type IdleCallback = (deadline: Deadline) => void;
export type IdleOptions = { timeout: number };

@Injectable({ providedIn: 'root' })
export class Idle {
  idleHandlers = new Map();
  stableObservable$;

  constructor(public ngZone: NgZone) {
    this.stableObservable$ = this.ngZone
      .onStable.pipe(observeOn(asyncScheduler), publish(), refCount());
  }

  public requestIdleCallback(callback) {
    if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
      window.requestIdleCallback(callback);
    } else {
      this.polyfillRequestIdleCallback(callback);
    }
  }

  public cancelIdleCallback(handler) {
    if (typeof window !== 'undefined' && 'cancelIdleCallback' in window) {
      window.cancelIdleCallback(handler);
    } else {
      this.polyfillCancelIdleCallback(handler);
    }
  }

  private polyfillCancelIdleCallback(handler) {
    const { unsubscribe, timerId }: any = this.idleHandlers.get(handler);

    if (unsubscribe) {
      unsubscribe();
    }
    if (timerId) {
      this.ngZone.runOutsideAngular(() => {
        clearTimeout(timerId);
      });
    }

    this.idleHandlers.delete(handler);
  }

  private polyfillRequestIdleCallback(callback: IdleCallback, { timeout }: IdleOptions = { timeout: 50 }) {
    let dispose;
    // compiling problem
    // eslint-disable-next-line no-underscore-dangle
    const _self = this;
    _self.ngZone.runOutsideAngular(() => {
      function cb(): void {
        const start: number = Date.now();
        const deadline: Deadline = {
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50 - (Date.now() - start));
          },
        };

        setTimeout(() => {
          deadline.didTimeout = true;
          if (dispose) {
            dispose.unsubscribe();
          }
        }, timeout || 50);

        callback(deadline);
      }

      if (this.ngZone.isStable) {
        const timerId = setTimeout(cb, 10);
        this.idleHandlers.set(callback, { timerId });
      } else {
        dispose = this.stableObservable$.pipe(debounceTime(10), take(1))
          .subscribe(
            () => {
              const timerId = setTimeout(cb, 10);
              this.idleHandlers.set(callback, {
                unsubscribe: dispose.unsubscribe,
                timerId,
              });
            });
        this.idleHandlers.set(callback, { unsubscribe: dispose.unsubscribe });
      }
    });
  }
}
