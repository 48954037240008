import { Injectable } from '@angular/core';
import { AsyncDialog } from '@scpc/modules/common/services/async-dialog';
import { DialogRef } from '@angular/cdk/dialog';
import { EmailBonusComponent } from '@scpc/modules/email-bonus/components/email-bonus/email-bonus.component';
import { Bonus } from '@scpc/dto';

@Injectable({ providedIn: 'any' })
export class EmailBonusDialogService extends AsyncDialog<EmailBonusComponent, { isAuthorized: boolean }, void> {

  public async open(data: { isAuthorized: boolean, emailBonus: Bonus }): Promise<DialogRef<void, EmailBonusComponent>> {
    const c = await import('../../email-bonus/components/email-bonus/email-bonus.component');

    return this.dialog.open(c.EmailBonusComponent, {
      ...this.defaultOptions(data),
      data: data.emailBonus,
    }).updatePosition() as DialogRef<void, EmailBonusComponent>;
  }

}
