import { Routes } from '@angular/router';
import { ProtectedGuard } from '@scpc/modules/auth/common';
import { ActivationGuard } from '@scpc/modules/common/guards/activation.guard';
import { DashboardComponent } from '@scpc/modules/common/components/dashboard/dashboard.component';
import { RefreshTokenGuard } from '@scpc/modules/common/guards/refresh-token.guard';
import { MaintenanceGuard } from '@scpc/modules/common/guards/maintenance.guard';
import { PageResolver } from '@scpc/modules/common/resolvers/page.resolver';

export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [RefreshTokenGuard],
    children: [
      {
        path: 'fafi/game',
        loadChildren: /* istanbul ignore next */ () => import('./modules/fafi/fafi-game.routing').then(r => r.ROUTES),
      },
      {
        path: 'deposits/process',
        loadChildren: /* istanbul ignore next */ () => import('./modules/deposits/deposits-process.routing').then(r => r.ROUTES),
      },
      {
        path: '',
        canActivate: [ActivationGuard],
        runGuardsAndResolvers: 'always',
        component: DashboardComponent,
        children: [
          {
            path: 'maintenance',
            data: {
              fullWidth: true,
            },
            resolve: { seo: PageResolver },
            canActivate: [MaintenanceGuard],
            loadComponent: /* istanbul ignore next */ () => import('./modules/common/components/maintenance/maintenance.component')
              .then(c => c.MaintenanceComponent),
          },
          {
            path: '',
            loadChildren: /* istanbul ignore next */ () => import('./modules/lobby/lobby.routing').then(r => r.ROUTES),
            pathMatch: 'full',
          },
          {
            path: 'lucky-numbers',
            canActivate: [MaintenanceGuard],
            loadChildren: /* istanbul ignore next */ () => import('./modules/lucky-numbers/lucky-numbers.routing').then(r => r.ROUTES),
          },
          {
            path: 'live-games',
            loadChildren: /* istanbul ignore next */ () => import('./modules/live-games/live-games.routing').then(r => r.ROUTES),
          },
          {
            path: 'betgames',
            loadChildren: /* istanbul ignore next */ () => import('./modules/betgames/betgames.routing').then(r => r.ROUTES),
          },
          {
            path: 'slots',
            loadChildren: /* istanbul ignore next */ () => import('./modules/slot-games/slot-games.routing').then(r => r.ROUTES),
          },
          {
            path: 'sports',
            loadChildren: /* istanbul ignore next */ () => import('./modules/sports/sports.routing').then(r => r.ROUTES),
          },
          {
            path: 'promotions',
            loadChildren: /* istanbul ignore next */ () => import('./modules/promotions/promotions.routing').then(r => r.ROUTES),
          },
          {
            path: 'fafi',
            loadChildren: /* istanbul ignore next */ () => import('./modules/fafi/fafi.routing').then(r => r.ROUTES),
          },
          {
            path: 'referral-program',
            loadChildren: /* istanbul ignore next */ () => import('./modules/landings/refer-friend/landing.refer-friend.bonuses.routing').then(r => r.ROUTES),
          },
          {
            path: 'about-us',
            loadChildren: /* istanbul ignore next */ () => import('./modules/about-us/about-us.routing').then(r => r.ROUTES),
          },
          {
            path: 'terms',
            loadChildren: /* istanbul ignore next */ () => import('./modules/terms/terms.routing').then(r => r.ROUTES),
          },
          {
            path: 'privacy-policy',
            loadChildren: /* istanbul ignore next */ () => import('./modules/privacy-policy/privacy-policy.routing').then(r => r.ROUTES),
          },
          {
            path: 'responsible-gambling-policy',
            loadChildren: /* istanbul ignore next */ () => import('./modules/responsible-gambling/responsible-gambling.routing').then(r => r.ROUTES),
          },
          {
            path: 'rules',
            loadChildren: /* istanbul ignore next */ () => import('./modules/rules/rules.routing').then(r => r.ROUTES),
          },
          {
            path: 'regulations',
            loadChildren: /* istanbul ignore next */ () => import('./modules/regulations/regulations.routing').then(r => r.ROUTES),
          },
          {
            path: 'protection-of-personal-information-act',
            loadChildren: /* istanbul ignore next */ () => import('./modules/popia/popia.routing').then(r => r.ROUTES),
          },
          {
            path: 'faq',
            loadChildren: /* istanbul ignore next */ () => import('./modules/faq/faq.routing').then(r => r.ROUTES),
          },
          {
            path: 'contact-us',
            loadChildren: /* istanbul ignore next */ () => import('./modules/contacts/contacts.routing').then(r => r.ROUTES),
          },
          {
            path: 'mobile',
            loadChildren: /* istanbul ignore next */ () => import('./modules/apps/apps.routing').then(r => r.ROUTES),
          },
          {
            path: 'blog',
            loadChildren: /* istanbul ignore next */ () => import('./modules/blog/blog.routing').then(r => r.ROUTES),
          },
          {
            path: 'yescares',
            loadChildren: /* istanbul ignore next */ () => import('./modules/cares/cares.routing').then(r => r.ROUTES),
          },
          {
            path: 'sign-in',
            loadChildren: /* istanbul ignore next */ () => import('./modules/auth/sign-in/sign-in.routing').then(r => r.ROUTES),
          },
          {
            path: 'sign-up',
            loadChildren: /* istanbul ignore next */ () => import('./modules/auth/sign-up/sign-up.routing').then(r => r.ROUTES),
          },
          {
            path: 'forgot-password',
            loadChildren: /* istanbul ignore next */ () => import('./modules/auth/forgot-password/forgot-password.routing').then(r => r.ROUTES),
          },
          {
            path: 'account',
            canActivate: [ProtectedGuard],
            canActivateChild: [ProtectedGuard],
            children: [
              { path: '', pathMatch: 'full', redirectTo: '/account/profile' },
              {
                path: 'profile',
                loadChildren: /* istanbul ignore next */ () => import('./modules/customer/customer.routing').then(r => r.ROUTES),
              },
              {
                path: 'wallet',
                children: [
                  { path: '', pathMatch: 'full', redirectTo: '/account/wallet/deposits' },
                  {
                    path: 'deposits',
                    loadChildren: /* istanbul ignore next */ () => import('./modules/deposits/deposits.routing').then(r => r.ROUTES),
                  },
                  {
                    path: 'withdrawals',
                    loadChildren: /* istanbul ignore next */ () => import('./modules/withdrawals/withdrawals.routing').then(r => r.ROUTES),
                  },
                  {
                    path: 'ctt',
                    loadChildren: /* istanbul ignore next */ () => import('./modules/ctt/ctt.routing').then(r => r.ROUTES),
                  },
                ],
              },
              {
                path: 'statements',
                loadChildren: /* istanbul ignore next */ () => import('./modules/statements/statements.routing').then(r => r.ROUTES),
              },
              {
                path: 'wagers',
                loadChildren: /* istanbul ignore next */ () => import('./modules/wagers/wagers.routing').then(r => r.ROUTES),
              },
              {
                path: 'bonus-programs-offers',
                loadChildren: /* istanbul ignore next */ () => import('./modules/bonuses/bonuses.routing').then(r => r.ROUTES),
              },
              {
                path: 'settings',
                loadChildren: /* istanbul ignore next */ () => import('./modules/settings/settings.routing').then(r => r.ROUTES),
              },
            ],
          },
        ],
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];
