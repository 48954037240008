<div class='scp-notification'
     [class.scp-notification-error]="data.type === 'ERROR'"
     [class.scp-notification-success]="data.type === 'SUCCESS'">
  <div class='scp-notification-icon'></div>
  @if (data.message) {
    <div class='scp-notification-message'
         [innerHTML]='sanitizer.bypassSecurityTrustHtml(data.message | translate: data.messageParams)'
         scpRouteTransformer>
    </div>
  }
  <button mat-icon-button (mousedown)='close($event)' class='scp-close-notification'>
    @if (data.type === 'ERROR') {
      <img src='/assets/images/svg/error-close.svg'
           alt=''
           height='16'
           width='16'
           loading='lazy' />
    } @else if (data.type === 'SUCCESS') {
      <img src='/assets/images/svg/success-close.svg'
           alt=''
           height='16'
           width='16'
           loading='lazy' />
    }
  </button>
</div>
