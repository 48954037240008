<div class='scp-products {{type}}'>
  @if (!reachBeginning) {
    <div class='scp-products__arrow scp-products__arrow__left'>
      <button mat-icon-button
              (click)='swiper.slidePrev()'
              [attr.aria-label]="'BUTTON.PREV_PAGE' | translate">
        <img src='/assets/images/svg/arrow-2.svg' width='24' height='24' alt=''>
      </button>
    </div>
  }
  <div class='swiper swiper-products-{{type}}'>
    <div class='swiper-wrapper'>
      @for (product of products; track product.name) {
        @if (product.link && !product.link.startsWith('/')) {
          <a class='swiper-slide scp-products__product'
             [href]='product.link'>
            <picture>
              <img [src]='product.icon' alt='' height='24' loading='lazy' [class]='product.name' />
            </picture>
            <div>{{ product.name }}</div>
          </a>
        } @else if (product.link.startsWith('/')) {
          <a class='swiper-slide scp-products__product'
             routerLink='{{ product.link }}'
             [routerLinkActiveOptions]='{ paths: product.link === "/" ? "exact" : "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored"}'
             routerLinkActive='is-active'>
            <picture>
              <img [src]='product.icon' alt='' height='24' loading='lazy' [class]='product.name' />
            </picture>
            <div>{{ product.name }}</div>
          </a>
        }
      }
    </div>
  </div>
  @if (!reachEnd) {
    <div class='scp-products__arrow scp-products__arrow__right'>
      <button mat-icon-button
              (click)='swiper.slideNext()'
              [attr.aria-label]="'BUTTON.NEXT_PAGE' | translate">
        <img src='/assets/images/svg/arrow-2.svg' width='24' height='24' alt=''>
      </button>
    </div>
  }
</div>
