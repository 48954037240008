<ngx-loading-bar color='#ff7922' [includeSpinner]='false' ref='http'></ngx-loading-bar>
@if (loader.value$ | async; as value) {
  <div class='overlay'></div>
}
@if (showCookiesHeader && (update.available | async) === null) {
  <scp-header-cookies (accepted)='acceptCookies()'></scp-header-cookies>
}
@if ((update.available | async) !== null) {
  <scp-header-update></scp-header-update>
}
<scp-header (toggleSidenav)='toggleSidenav()'
            [menuButtonHidden]='!isAuthorized'
            [toursDisabled]='isVisibleSkeletonLoader'
            (tour)='showTour($event)'
            (hideTour)='hideTour()'
            [cart]='!isFloatCart'
            [tours]='tours'
            [customer]='customer'
            [balance]='balance'
            [class]='headerClass'>
</scp-header>
<mat-sidenav-container [hasBackdrop]='!isWeb && isBrowser' class='scp-sidenav-container' [class.auth]='isAuthorized'>
  <mat-sidenav #sidenav
               class='scp-sidenav'
               [class.ssr]='!isBrowser'
               [mode]="isWeb || !isBrowser ? 'side' : 'over'"
               [opened]='!isBrowser || isWeb'
               [autoFocus]='false'>
    <div class='scp-scrollbar scp-dashboard-menu'>
      @if (isAuthorized && customer) {
        @if (!isWeb && isBrowser) {
          <div class='scp-logo'>
            <div>
              <img src='{{logo}}' alt='' width='80' loading='lazy' />
            </div>
            <button mat-icon-button class='scp-menu-close' (click)='sidenav.toggle()'>
              <img src='/assets/images/svg/close.svg' alt='' height='16' width='16' class='logo' loading='lazy' />
            </button>
          </div>
        }
        @if (!isWeb && isBrowser) {
          <div class='scp-divider'></div>
        }
      }
      <mat-nav-list class='scp-navigation-menu'>
        @if (isAuthorized && customer) {
          <div class='scp-navigation-category scp-auto-height'>
            <a class='scp-user-block'
               mat-list-item
               (click)='closeSidenav()'
               [routerLinkActive]="'active'"
               [routerLink]="'/account/profile'"
               [routerLinkActiveOptions]='{ exact: true }'>
              <img src='/assets/images/svg/profile.svg' alt='' height='24' width='24' class='logo filter'
                   loading='lazy' />
              <div class='scp-user-data'>
                <div class='scp-username'>
                  @if (isLoaded && customer) {
                    {{ customer.firstName + ' ' + customer.lastName }}
                  }
                </div>
                @if (balance) {
                  <div class='scp-balances'>
                    <div>
                      <div class='scp-cash-balance-title'>
                        {{ 'TOTAL_BALANCE' | translate }}
                      </div>
                      <div class='scp-cash-balance'>
                        {{ balance.TOTAL_CASH[0] | formatMoney }}
                      </div>
                    </div>
                    <div>
                      <div class='scp-cash-balance-title'>
                        {{ 'BONUS_BALANCE' | translate }}
                      </div>
                      <div class='scp-bonus-balance'>
                        {{ balance.BONUS[0] | formatMoney }}
                      </div>
                    </div>
                    <div>
                      <div class='scp-cash-balance-title'>
                        {{ 'AVAILABLE_TO_WITHDRAWAL_BALANCE' | translate }}
                      </div>
                      <div class='scp-balance-withdraw-value'>
                        {{ balance.CASH[0] | formatMoney }}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </a>
          </div>
          <div class='scp-divider'></div>
        } @else {
          <div class='scp-navigation-category'>
            <scp-btn class='scp-sign-in'
                     [hasMarginTop]='false'
                     height='36'
                     type='16'
                     text='TITLE.SIGN_IN_OR_SIGN_UP_2'
                     url='/sign-in'>
            </scp-btn>
          </div>
          <div class='scp-divider'></div>
          @for (product of configService.products; track product.type) {
            @if (product.wagerTypes?.length) {
              <div class='scp-navigation-category scp-navigation-category-opened'>
                @if (product.link && !product.link.startsWith('/')) {
                  <a mat-list-item
                     [href]='product.link'>
                    <img [src]='product.icon' alt='' height='24' width='24' class='logo' loading='lazy' />
                    <span>{{ product.name }}</span>
                  </a>
                } @else if (product.link.startsWith('/')) {
                  <a mat-list-item
                     [routerLink]='product.link'
                     [routerLinkActiveOptions]='{ paths: product.link === "/" ? "exact" : "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored"}'
                     [routerLinkActive]="'active'">
                    <img [src]='product.icon' alt='' height='24' width='24' class='logo' loading='lazy' />
                    <span>{{ product.name }}</span>
                  </a>
                }
              </div>
              <div class='scp-divider'></div>
            }
          }
        }
        @for (category of categories; track category.title) {
          <div [class.scp-navigation-category-opened]='!category.closed'
               class='scp-navigation-category'>
            @if (!category.routerLink?.startsWith("http")) {
              <a mat-list-item
                 [routerLink]='category.routerLink || activatedRoute.snapshot.url'
                 [routerLinkActive]="!category.items?.length ? 'active' : ''"
                 [queryParams]='category.queryParams'
                 [skipLocationChange]='!!category.items?.length'
                 (click)='category.items?.length ? updateMenuState(category, categoryRef, $event) : closeSidenav()'>
                <img [src]="category.logo ? '/assets/images/svg/' + category.logo +'.svg' : ''"
                     [style.opacity]='category.logo ? 1: 0'
                     alt=''
                     height='24'
                     width='24'
                     class='logo filter'
                     loading='lazy' />
                <span class='title'>{{ category.title | translate }}</span>
                @if (category.items?.length) {
                  <span [class.scp-category-arrow-rotated]='!category.closed' class='more'>
                    <img src='/assets/images/svg/menu-arrow.svg'
                         alt=''
                         height='16'
                         width='16'
                         class='scp-category-arrow'
                         loading='lazy' />
                  </span>
                }
              </a>
            }
            @if (category.routerLink?.startsWith("http")) {
              <a mat-list-item
                 [href]='category.routerLink'
                 (click)='category.items?.length ? updateMenuState(category, categoryRef, $event) : closeSidenav()'>
                <img [src]="category.logo ? '/assets/images/svg/' + category.logo +'.svg' : ''"
                     [style.opacity]='category.logo ? 1: 0'
                     alt=''
                     height='24'
                     width='24'
                     class='logo filter'
                     loading='lazy' />
                <span class='title'>{{ category.title | translate }}</span>
              </a>
            }
            <div #categoryRef class='scp-navigation-category-items' [class.scp-closed]='category.closed'>
              @for (item of category.items; track menuTrackBy(item)) {
                @if (item.isActive) {
                  <scp-dashboard-menu-item [url]='item.routerLink'
                                           [queryParams]='item.queryParams'
                                           [title]='item.title'
                                           [icon]='item.icon'
                                           [iconSize]='item.iconSize'
                                           [count]='item.count'
                                           (click)='closeSidenav()'
                                           tabindex='-1'>
                  </scp-dashboard-menu-item>
                }
              }
            </div>
            <div class='scp-divider'></div>
          </div>
        }
        @if (isAuthorized && customer; ) {
          <div class='scp-fill-space' #space>
            <div class='scp-divider' [style.display]="space.offsetHeight > 1 ? 'flex' : 'none'"></div>
          </div>
          <div class='scp-sign-out-block scp-navigation-category'>
            <a mat-list-item [routerLink]='signOutURL' (click)='signOut()'>
              <img src='/assets/images/svg/sign-out.svg' alt='' height='24' width='24' class='logo filter'
                   loading='lazy' />
              {{ 'SIGN_OUT' | translate }}
            </a>
          </div>
        }
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class='ssr'>
    <div class='scp-content'>
      <div class='scp-scrollbar-content' #dashboardScrollingBlock
           (onResize)='onResize(dashboardScrollingBlock.scrollTop)'>
        <div class='scp-products'>
          <scp-products type='mobile'></scp-products>
        </div>
        @if (smartBannerConfig?.enabled && !isVisibleSkeletonLoader) {
          <scp-smart-banner [config]='smartBannerConfig'
                            [logo]='smallLogo'
                            [siteName]='configService.siteName'
                            [isAuthorized]='isAuthorized'
                            (close)='smartBannerConfig = null'>
          </scp-smart-banner>
        }
        @if (customer?.status === "DUPLICATE_ID_ARCHIVED" && !this.isVisibleSkeletonLoader) {
          <div class='scp-fica'>
            <div>
              <div class='scp-text'>
                <div class='scp-notification-error'></div>
                <div>
                  <span>
                  @if (customer.relatedAccount?.type === 'phone') {
                    {{ "ACTIVE_PHONE_ACCOUNT" | translate: customer.relatedAccount }}
                  } @else if (customer.relatedAccount?.type === 'email') {
                    {{ "ACTIVE_EMAIL_ACCOUNT" | translate: customer.relatedAccount }}
                  } @else {
                    {{ 'LIVE_CHAT_ACCOUNT' | translate }}
                  }
                  </span>
                </div>
                @if (!customer.relatedAccount) {
                  <scp-btn
                    [hasMarginTop]='false'
                    text='Livechat'
                    height='36'
                    type='1'
                    width='73'
                    (click)='document.querySelector(".scp-header__chats")?.click()'>
                  </scp-btn>
                }
              </div>
            </div>
          </div>
        } @else if (customer?.status === "DECEASED" && !this.isVisibleSkeletonLoader) {
          <div class='scp-fica'>
            <div>
              <div class='scp-text'>
                <div class='scp-notification-error'></div>
                <div>
                  <span>
                    {{ 'LIVE_CHAT_ACCOUNT' | translate }}
                  </span>
                </div>
              </div>
              <scp-btn
                [hasMarginTop]='false'
                text='Livechat'
                height='36'
                type='1'
                width='73'
                (click)='document.querySelector(".scp-header__chats")?.click()'>
              </scp-btn>
            </div>
          </div>
        } @else if (phoneRequired | async) {
          <div class='scp-verify-phone'>
            <div>
              <div class='scp-text'>
                <div class='scp-notification-error'></div>
                @if (this.customer.contactDetails?.mobileNumber) {
                  {{ 'YOUR_PHONE_IS_UNVERIFIED' | translate }}
                } @else {
                  {{ 'Your mobile number is missing' | translate }}
                }
              </div>
              @if (this.customer.contactDetails?.mobileNumber) {
                <scp-btn [hasMarginTop]='false'
                         text='VERIFY'
                         height='34'
                         type='1'
                         width='71'
                         routerLink='/account/profile/phone/verify'>
                </scp-btn>
              } @else {
                <scp-btn [hasMarginTop]='false'
                         text='Add'
                         height='34'
                         type='1'
                         width='55'
                         routerLink='/account/profile/phone'>
                </scp-btn>
              }
            </div>
          </div>
        } @else if (kycRequired || (documentsRequired | async)) {
          <div class='scp-fica'>
            @switch (customer.kyc?.status) {
              @case ("IN_PROGRESS") {
                <div>
                  <div class='scp-text info'>
                    <div class='scp-notification-info'></div>
                    {{ 'Documents verification is in progress. Usually it might take up to 15 min' | translate }}
                  </div>
                </div>
              }
              @case ("INVALID") {
                <div>
                  <div class='scp-text'>
                    <div class='scp-notification-error'></div>
                    <div>
                      {{ customer.kyc.reason }}
                      @if ((customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && (customer.forbidden?.includes("ekyc") || customer.ekyc?.status === "CONFIRMED")) {
                        <span>Contact our support via livechat</span>
                      } @else {
                        <span>Please verify once again</span>
                      }
                    </div>
                  </div>
                  @if ((customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && (customer.forbidden?.includes("ekyc") || customer.ekyc?.status === "CONFIRMED")) {
                    <scp-btn
                      [hasMarginTop]='false'
                      text='Livechat'
                      height='36'
                      type='1'
                      width='73'
                      (click)='document.querySelector(".scp-header__chats")?.click()'>
                    </scp-btn>
                  } @else {
                    @if (!((customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && (customer.forbidden?.includes("ekyc") || customer.ekyc?.status === "CONFIRMED"))) {
                      <scp-btn
                        [hasMarginTop]='false'
                        text='VERIFY'
                        height='36'
                        type='1'
                        width='73'
                        [routerLink]='"/account/profile/kyc"'>
                      </scp-btn>
                    }
                  }
                </div>
              }
              @default {
                <div>
                  <div class='scp-text'>
                    <div class='scp-notification-error'></div>
                    <div>
                      <span>{{ 'YOUR_SA_ID_IS_UNVERIFIED' | translate }}</span>
                      @if ((customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && (customer.forbidden?.includes("ekyc") || customer.ekyc?.status === "CONFIRMED")) {
                        <span>. Contact our support via livechat</span>
                      }
                    </div>
                  </div>
                  @if ((customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && (customer.forbidden?.includes("ekyc") || customer.ekyc?.status === "CONFIRMED")) {
                    <scp-btn [hasMarginTop]='false'
                             text='Livechat'
                             height='36'
                             type='1'
                             width='73'
                             (click)='document.querySelector(".scp-header__chats")?.click()'>
                    </scp-btn>
                  } @else {
                    <scp-btn [hasMarginTop]='false'
                             text='VERIFY'
                             height='36'
                             type='1'
                             width='73'
                             [routerLink]="'/account/profile/kyc'">
                    </scp-btn>
                  }
                </div>
              }
            }
          </div>
        }
        <div class='scp-dashboard-content full-width' [class]='layoutClass' [class.has-seo]='seo'>
          <div class='scp-page' [class]='layoutClass' [class.authorized]='isAuthorized'>
            <scp-title [isHiddenTitle]='isVisibleSkeletonLoader'></scp-title>
            @if (isVisibleSkeletonLoader) {
              <scp-skeleton-loader></scp-skeleton-loader>
            }
            <div class='w'
                 [class.not-full]="!layoutClass?.includes('full-width')"
                 [hidden]='isVisibleSkeletonLoader'
                 [style.display]="isVisibleSkeletonLoader ? 'none' : 'flex'">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
        @if (seo) {
          <scp-seo class='scp-seo'
                   [seoText]='seo'
                   [loading]='isVisibleSkeletonLoader'
                   [class.authorized]='isAuthorized'>
          </scp-seo>
        }
        @if (hasFooter) {
          <scp-footer></scp-footer>
        }
      </div>
    </div>
    <button mat-mini-fab (click)='dashboardScrollingBlock.scrollTo({ top: 0, behavior: "smooth" })' class='scp-to-top'
            #top
            aria-label='Expand'>
      <img src='/assets/images/svg/scroll-to-top.svg' alt='' height='16' width='16' loading='lazy' />
    </button>
    <div [style.display]="isVisibleSkeletonLoader || !isFloatCartAllowed ? 'none' : 'block'">
      <scp-cart-button [float]='true' [visible]='isFloatCart && isFloatCartAllowed && !isVisibleSkeletonLoader'
                       class='cart-button'></scp-cart-button>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
