import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Promotion, PromotionError } from '@scpc/dto/promotion';
import { Observable } from 'rxjs';
import { LayoutService, ScpService } from '@scpc/modules/common/services';
import { LuckyNumbersCartService } from '@scpc/modules/cart/services/lucky-numbers-cart.service';
import { Router } from '@angular/router';
import { WagerType } from '@scpc/dto';
import { isPlatformBrowser } from '@angular/common';
import { CommonCartService } from '@scpc/modules/cart/services/common-cart.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class PromotionService {

  private promotion: Promotion;
  private error: PromotionError[] | HttpErrorResponse | null;

  constructor(private readonly router: Router,
              private readonly scpService: ScpService,
              private readonly cartService: LuckyNumbersCartService,
              private readonly commonCartService: CommonCartService,
              private readonly layoutService: LayoutService,
              @Inject(PLATFORM_ID) private readonly platformId: string) {
  }

  public get hasPromotion(): boolean {
    return !!this.promotion && !this.error;
  }

  public async play(promotion: Promotion): Promise<void> {
    if (WagerType.LOTTO_WAGER === promotion.wagerType && (await this.commonCartService.isBetAllowed('LUCKY_NUMBERS'))) {
      await this.router.navigate([`/lucky-numbers/` + promotion.events[0].eventId], { queryParams: { promotion: promotion.id } });
    }
  }

  public getPromotion(promotionId: string): Observable<void> {
    return new Observable<void>(subscriber => {
      this.promotion = null;
      this.error = null;
      this.scpService.getPromotion(promotionId, true).subscribe({
        next: async (promotion: Promotion): Promise<void> => {
          this.promotion = promotion;
          if (!promotion.errors?.length) {
            if (WagerType.LOTTO_WAGER === promotion.wagerType && isPlatformBrowser(this.platformId)) {
              await this.cartService.addWagersWithPromotion(promotion.luckyNumbersWagers, promotion);
            }
          } else {
            this.error = promotion.errors;
          }
          subscriber.next();
          subscriber.complete();
        }, error: (error): void => {
          this.error = error;
          subscriber.next();
          subscriber.complete();
        },
      });
    });
  }

  public showErrorsIfRequired(): void {
    if (this.promotion && this.promotion.errors?.length) {
      const type: string = this.promotion.errors[0].type;
      switch (this.promotion.errors[0].type) {
        case 'PROMOTION_NOT_AVAILABLE_ANY_MORE':
        case 'PROMOTION_NOT_AVAILABLE_AT_THIS_MOMENT':
        case 'PROMOTION_HAS_CONCURRENT_BONUS_OFFER':
          this.layoutService.showErrorNotification('ERROR.' + type);
          break;
      }
    }
  }

  public clear() {
    return new Observable<void>(subscriber => {
      this.promotion = null;
      this.error = null;
      subscriber.next();
      subscriber.complete();
    });
  }

}
