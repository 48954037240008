import { ValueSegment } from '@scpc/dto/customer';
import { ChatAvailabilityConfig } from '@scpc/dto/settings';

export function isOpenMode(isAuthorized: boolean,
                           valueSegment?: ValueSegment,
                           chatAvailabilityConfig?: ChatAvailabilityConfig): boolean {
  if (!chatAvailabilityConfig) {
    return true;
  }

  if (!isAuthorized) {
    return chatAvailabilityConfig.anonymous;
  } else if (!valueSegment) {
    return chatAvailabilityConfig.unknown;
  } else if (valueSegment.name === 'Diamond') {
    return chatAvailabilityConfig.diamond;
  } else if (valueSegment.name === 'Platinum') {
    return chatAvailabilityConfig.platinum;
  } else if (valueSegment.name === 'Gold') {
    return chatAvailabilityConfig.gold;
  } else if (valueSegment.name === 'Silver') {
    return chatAvailabilityConfig.silver;
  } else if (valueSegment.name === 'Bronze') {
    return chatAvailabilityConfig.silver;
  } else if (valueSegment.name === 'Dormant') {
    return chatAvailabilityConfig.dormant;
  } else if (valueSegment.name === 'Non Depositor') {
    return chatAvailabilityConfig.nonDepositor;
  }

  return chatAvailabilityConfig.unknown;
}
