import { Directive, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ElementSides, isInViewport, TourAnchorDirective } from 'ngx-ui-tour-core';
import { TourStepTippyTemplateService } from './tippy-tour-step-template.service';
import { TourTippyService } from '@scpc/modules/common/tour/tippy-tour.service';
import { TourTippyStepOption } from '@scpc/modules/common/tour/tippy-step-option';
import { TippyInstance, TippyService } from '@ngneat/helipopper';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';
import { sticky } from 'tippy.js';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[tourAnchor]' })
export class TourAnchorTippyDirective implements OnInit, OnDestroy, TourAnchorDirective {

  @Input()
  public anchor: string;

  @HostBinding('class.touranchor--is-active')
  public isActive: boolean;

  private tippy: TippyInstance;

  constructor(
    public readonly element: ElementRef,
    private readonly tourService: TourTippyService,
    private readonly tourStepTemplate: TourStepTippyTemplateService,
    private readonly tippyService: TippyService,
    private readonly zone: NgZone,
    private readonly idle: Idle
  ) {
  }

  @Input()
  public set tourAnchor(anchor: string) {
    if (this.anchor === anchor) {
      return;
    }
    /* istanbul ignore if */
    if (!anchor) {
      this.hideTourStep();
      this.tourService.unregister(this.anchor);
    }
    /* istanbul ignore if */
    if (this.anchor && anchor) {
      this.tourService.unregister(this.anchor);
    }
    this.anchor = anchor;
    if (this.anchor) {
      this.tourService.unregister(anchor);
      this.tourService.register(this.anchor, this);
    }
    /* istanbul ignore next */
    if (this.tourService.currentStep && this.tourService.currentStep.anchorId === anchor && anchor) {
      this.hideTourStep();
      if (this.tourService.index) {
        this.showTourStep(this.tourService.currentStep);
      }
    }
  }

  public ngOnInit(): void {
    this.tourService.unregister(this.anchor);
    if (this.anchor) {
      this.tourService.register(this.anchor, this);
    }
  }

  public ngOnDestroy(): void {
    this.hideTourStep();
    this.tourService.unregister(this.anchor);
    this.anchor = null;
  }

  public showTourStep(step: TourTippyStepOption): void {
    this.hideTourStep();
    if (this.tourService.currentStep === step && this.anchor === step.anchorId) {
      this.isActive = true;
      this.tippy = this.tippyService.create(
        this.element.nativeElement,
        this.tourStepTemplate.template,
        {
          arrow: true,
          appendTo: step.appendTo,
          maxWidth: 290,
          hideOnClick: false,
          duration: 500,
          animation: 'scale',
          showOnCreate: false,
          theme: 'light-border',
          interactive: true,
          inertia: true,
          placement: step.placement,
          offset: [0, 8],
          sticky: true,
          plugins: [sticky]
        }
      );

      if (!step.disableScrollToAnchor) {
        /* istanbul ignore if */
        if (!isInViewport(this.element.nativeElement, ElementSides.Bottom)) {
          this.element.nativeElement.scrollIntoView(false);
        } else /* istanbul ignore next */ if (!isInViewport(this.element.nativeElement, ElementSides.All)) /* istanbul ignore next */ {
          this.element.nativeElement.scrollIntoView(true);
        }
      }

      this.idle.requestIdleCallback(() => this.zone.runOutsideAngular(() => this.tippy.show()));
    }
  }

  public hideTourStep(): void {
    this.isActive = false;
    this.tippy?.destroy();
    this.tippy = null;
  }

}
