import {
  ApplicationRef,
  Inject,
  inject,
  Injectable,
  NgZone,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Bookmakers, CmsService } from '@scpc/modules/common/services/cms.service';
import { AuthenticationService } from '@scpc/modules/common/services/authentication.service';
import { catchError, delay, first, map, switchMap } from 'rxjs/operators';
import { CookiesService } from '@scpc/modules/cookies/cookies.service';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { addHours } from '@scpc/modules/promotions/utils/date';
import { Observable, of } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { BookmakersComponent } from '@scpc/modules/common/components/bookmakers/bookmakers.component';
import { StorageService } from '@scpc/modules/common/services/storage.service';

@Injectable({ providedIn: 'root' })
export class BookmakersService {

  private isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));
  private zone: NgZone = inject(NgZone);
  private cmsService: CmsService = inject(CmsService);
  private authenticationService: AuthenticationService = inject(AuthenticationService);
  private applicationRef: ApplicationRef = inject(ApplicationRef);
  private cookiesService: CookiesService = inject(CookiesService);
  private configService: ConfigService = inject(ConfigService);
  private dialog: Dialog = inject(Dialog);
  private document: Document = inject(DOCUMENT);

  constructor(@Optional() @Inject('USER_AGENT') private readonly userAgent?: string) {
    if (this.isBrowser) {
      this.zone.runOutsideAngular((): void => {
        this.applicationRef.isStable
          .pipe(
            first((isStable: boolean): boolean => isStable),
            switchMap(() => this.authenticationService.isAuthorized()),
            map((isAuthorized: boolean): boolean => {
              if (isAuthorized) {
                this.cookiesService.put('sign-in', 'true');
                return false;
              }
              return this.isAllowed() && this.isAllowedByUserAgent() && this.isAllowedBySignInBefore() && this.isAllowedByOpenTime();
            }),
            switchMap((isRequired: boolean): Observable<Bookmakers | null> => {
              return isRequired ? this.cmsService.getBookmakers().pipe(delay(5000)) : of(null);
            }),
            catchError((): Observable<null> => of(null)),
          )
          .subscribe((bookmakers: Bookmakers | null): void => {
            if (bookmakers?.bookmakers.length) {
              this.cookiesService.put('bookmakers-banner', Date.now().toString(), { expires: StorageService.getNextYearDate() });
              this.dialog.open(BookmakersComponent, {
                data: { bookmakers, siteName: this.configService.siteName },
                backdropClass: 'scp-bookmakers-backdrop',
                panelClass: 'scp-bookmakers-panel',
                closeOnNavigation: false,
                disableClose: true,
                maxWidth: 495 + 16,
                width: '100%',
              });
            }
          });
      });
    }
  }

  private isAllowed(): boolean {
    return this.configService.otherBookmakers && this.cookiesService.get('sign-in') !== 'true';
  }

  private isAllowedByUserAgent(): boolean {
    const userAgent: string = this.userAgent || this.document.defaultView.navigator.userAgent;
    return !userAgent.includes('Googlebot');
  }

  private isAllowedBySignInBefore(): boolean {
    return this.cookiesService.get('sign-in') !== 'true';
  }

  private isAllowedByOpenTime(): boolean {
    const lastShowTime: number | undefined = Number(this.cookiesService.get('bookmakers-banner'));
    return !lastShowTime || addHours(new Date(lastShowTime), this.configService.otherBookmakersHidePeriod).getTime() < Date.now();
  }

}
