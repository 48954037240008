import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { Money } from '@scpc/dto';
import { Game } from '@scpc/modules/games-lobby/model';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { Event } from '@scpc/modules/sports/dto/event';
import { SportCartSelectionId } from '@scpc/modules/cart/model';

export type Purchase = {
  transactionId: string,
  amount: string,
  currency: string,
  contentIds: string[]
}

export type AnalyticsData = {
  game?: Game;
  draw?: Draw;
  amount?: Money;
  purchase?: Purchase;
  event?: Event;
  selection?: SportCartSelectionId;
} & {
  [key: string]: string
};

export abstract class AnalyticsService<T> {

  protected readonly isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  protected browserGlobals = {
    windowRef(): T {
      return window as unknown as T;
    },
  };

  public abstract event(category: string, action: string, label?: string, value?: string, data?: AnalyticsData): void;

}
