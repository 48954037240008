import { HttpClient } from '@angular/common/http';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DrawResult } from '@scpc/modules/lucky-numbers/dto/draw-result';
import { Result } from '../dto/result';
import { StorageService } from '@scpc/modules/common/services';
import { LuckyNumbersWager } from '@scpc/modules/lucky-numbers/dto/lucky-numbers.wager';
import { Bonus, Money } from '@scpc/dto';

@Injectable({
  providedIn: 'root',
})
export class LuckyNumbersService {

  constructor(private http: HttpClient,
              private storageService: StorageService) {
  }

  private static getDefaultPath(): string {
    return `/scp/api/1.0/lucky-numbers/`;
  }

  public getDraws(type?: string, drawId?: string[], limit?: number): Observable<Draw[]> {
    let queryParams = '?topMarkets=true';
    if (type) {
      queryParams += `&type=${type}`;
    }
    if (limit) {
      queryParams += `&limit=${limit}`;
    }
    if (drawId?.length) {
      drawId.forEach((draw) => {
        queryParams += `&drawId=${draw}`;
      });
    }
    return this.http.get<Draw[]>(LuckyNumbersService.getDefaultPath() + `draws${queryParams}`);
  }

  public getDrawsResults(type?: string, drawId?: string[]): Observable<DrawResult[]> {
    let queryParams = '?';
    if (type) {
      queryParams += `type=${type}`;
    }
    if (drawId?.length) {
      queryParams += type ? '&' : '';
      drawId.forEach((draw, i) => {
        queryParams += `drawId=${draw}${drawId.length - 1 === i ? '' : '&'}`;
      });
    }
    return this.http.get<DrawResult[]>(LuckyNumbersService.getDefaultPath() + `draws/results${queryParams}`);
  }

  public getDrawResults(drawId: string, next?: string, from?: number, to?: number): Observable<Result> {
    let queryParams = '?pageSize=12';
    if (from) {
      queryParams += `&from=${from}`;
    }
    if (to) {
      queryParams += `&to=${to}`;
    }
    if (next) {
      queryParams += `&next=${next}`;
    }
    return this.http.get<Result>(LuckyNumbersService.getDefaultPath() + `draws/${drawId}/results${queryParams}`);
  }

  public getDraw(drawId: string,
                 similar: string | undefined,
                 frequency: boolean = true,
                 markets: boolean = true,
                 topMarkets: boolean = true): Observable<Draw> {
    const path = `draws/${drawId}?similar=${similar || ''}&frequency=${frequency}&markets=${markets}&topMarkets=${topMarkets}`;
    return this.http.get<Draw>(LuckyNumbersService.getDefaultPath() + path);
  }

  public registerWagers(wagers: LuckyNumbersWager[]): Observable<{
    emailBonus?: Bonus,
    wagersIds: { [key: string]: string; },
    subscriptionsIds: { [key: string]: string; }
  }> {
    return this.http.post<{
      emailBonus?: Bonus,
      wagersIds: { [key: string]: string; },
      subscriptionsIds: { [key: string]: string; }
    }>(`/scp/api/1.1/customers/${this.storageService.customerId}/lucky-numbers/wagers`, wagers);
  }

  public getPreferences(): Observable<{
    stake?: Money;
  }> {
    return this.http.get<{
      stake: undefined,
    }>(`/scp/api/1.0/customers/${this.storageService.customerId}/lucky-numbers/preferences?currency=${this.storageService.getCurrency()}`);
  }

}
