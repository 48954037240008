import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter, Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output, PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';

import {
  ResizeObserverService,
} from './../services/resize-observer.service';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[onResize]',
  standalone: true,
})
export class ResizeObserverDirective implements AfterViewInit, OnChanges, OnDestroy {

  @Input()
  public resizeBoxModel: string = '';

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onResize: EventEmitter<ResizeObserverEntry> = new EventEmitter<ResizeObserverEntry>();
  private observing: boolean = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly ngxResizeObserverService: ResizeObserverService,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {
  }

  public ngAfterViewInit(): void {
    this.observe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.observing && (changes['resizeBoxModel'] || changes['onResize'])) {
      this.unobserve();
      this.observe();
    }
  }

  public ngOnDestroy(): void {
    this.unobserve();
  }

  private observe(): void {
    if (!this.observing && isPlatformBrowser(this.platformId)) {
      this.ngxResizeObserverService.observe(
        this.elementRef.nativeElement,
        resize => this.onResize.emit(resize),
        this.resizeBoxModel,
      );
      this.observing = true;
    }
  }

  private unobserve(): void {
    if (this.observing) {
      this.ngxResizeObserverService.unobserve(this.elementRef.nativeElement);
      this.observing = false;
    }
  }

}
