import { Injectable } from '@angular/core';
import { AsyncDialog } from '@scpc/modules/common/services/async-dialog';
import { DialogRef } from '@angular/cdk/dialog';
import { Bonus } from '@scpc/dto';
import {
  LuckyNumbersCartComponent,
} from '@scpc/modules/cart/components/lucky-numbers-cart/lucky-numbers-cart.component';
import { SportCartComponent } from '@scpc/modules/cart/components/sport-cart/sport-cart.component';
import { environment } from '@scp-env/environment';

@Injectable({ providedIn: 'root' })
export class CartDialogService extends AsyncDialog<(LuckyNumbersCartComponent | SportCartComponent),
  { isAuthorized: boolean },
  { emailBonus?: Bonus, numberOfBets?: number, review?: boolean }> {

  public async open(data: { isAuthorized: boolean, product: 'SPORT' | 'LUCKY_NUMBERS', clear?: boolean }):
    Promise<DialogRef<{
      emailBonus?: Bonus,
      numberOfBets?: number,
      review?: boolean,
      clear?: boolean
    }, LuckyNumbersCartComponent | SportCartComponent>> {
    const c =
      data.product === 'LUCKY_NUMBERS' && !data.clear || !environment.isSportCart
        ? (await import('../components/lucky-numbers-cart/lucky-numbers-cart.component')).LuckyNumbersCartComponent
        : (await import('../components/sport-cart/sport-cart.component')).SportCartComponent;

    return this.dialog.open(c as any, {
      ...this.defaultOptions(data),
      autoFocus: 'dialog',
      data: {
        clear: data.clear || false,
      },
    }).updatePosition() as DialogRef<{
      emailBonus?: Bonus,
      numberOfBets?: number,
      review?: boolean,
      clear?: boolean
    }, LuckyNumbersCartComponent | SportCartComponent>;
  }

}
