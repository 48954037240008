<div class='scp-footer'>
  <ul class='scp-footer__list'>
    @for (item of items; track item.route; ) {
      <li>
        @if (item.external) {
          <a [href]='item.route'
             target='_blank'
             rel='nofollow noopener'>{{ item.title | translate }}
          </a>
        } @else {
          <a [routerLink]='item.route' routerLinkActive='active'>{{ item.title | translate }}</a>
        }
        <span>|</span>
      </li>
    }
  </ul>
  @if (content) {
    <div class='footer' [innerHtml]='content' [class.hide-apps]='isNativeApp'></div>
  }
  <scp-clock></scp-clock>
</div>
