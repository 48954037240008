import { SportCartSelection, SportCartSelectionId } from '@scpc/modules/cart/model';
import { Injectable } from '@angular/core';
import { Collection } from 'ngx-collection';

export function toSelectionId(selection: SportCartSelection): SportCartSelectionId {
  return {
    id: selection.id,
    eventId: selection.eventId,
    marketUniqId: selection.marketUniqId,
    outcomeId: selection.outcomeId,
  };
}

export function isSameSelectionId(o1: SportCartSelectionId, o2: SportCartSelectionId): boolean {
  return (o1.id && o1.id === o2.id) || (o1.eventId === o2.eventId && o1.marketUniqId === o2.marketUniqId && o1.outcomeId === o2.outcomeId);
}

@Injectable({ providedIn: 'root' })
export class SportSelectionsCollection extends Collection<SportCartSelectionId> {

  constructor() {
    super({
      allowFetchedDuplicates: true,
      comparator: isSameSelectionId,
      errReporter: console.error,
    });
  }

}
