import { Injectable } from '@angular/core';

import { CookiesService } from '../cookies.service';
import { CookiesOptions } from '../cookies-options';
import { CookiesOptionsService } from '../cookies-options.service';
import { isBlank, isString, mergeOptions, safeDecodeURIComponent } from '../utils';

@Injectable()
export class BrowserCookiesService extends CookiesService {

  constructor(cookiesOptions: CookiesOptionsService) {
    super(cookiesOptions);
   // this.put('cookies-accepted', 'true');
  //  this.put('expires_at', '2023-08-16T06%3A48%3A53.189Z');
  //  this.put('customerId', '1ebbb5bc-4a11-4c4b-882b-0e28b5b27b48');
  //  this.put('refresh_token', 'd45b4rBlxY-1PPn3KCnucVtY60eOCXRA4ctorTXuFpA');
 //   this.put('access_token', 'eyJraWQiOiJWTk5BdU5IdHNYLWpPSXpycW9zNVNsVUtHVUNWRmpQampoUlRmdlFaVFM0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULlBSajRqUkYxSzU4a3VIdG1SNFFqWDA5ZTJpR1N1SzVmbXpEdzNfY2JBdmsub2FyMjg4bWJ0ZGNBWTYwUHEwaDciLCJpc3MiOiJodHRwczovL2Rldi01MTYwNTEub2t0YXByZXZpZXcuY29tL29hdXRoMi9hdXNiZWdubHp2eXF6bGx3ejBoNyIsImF1ZCI6ImN1c3RvbWVycyIsImlhdCI6MTY5MjA4MjEzMywiZXhwIjoxNjkyMTY4NTMzLCJjaWQiOiIwb2FiZTV1a2hxeTBRR3dicTBoNyIsInVpZCI6IjAwdTEwaHBpaDh4R0c3MVdzMGg4Iiwic2NwIjpbIm9mZmxpbmVfYWNjZXNzIl0sImF1dGhfdGltZSI6MTY5MTM5NzA2Niwic3ViIjoidWF0fHdlYnwxMDAwMDAwfCszODA2NzE3MDY1ODVAbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJjdXN0b21lcklkIjoiMWViYmI1YmMtNGExMS00YzRiLTg4MmItMGUyOGI1YjI3YjQ4In0.fVPvPa_5Wal_i8uycWZ7f8RmTLo0fdqAeZURcTdIo_fPpd8bG15KXvKt1uGhoiYDE0WM-oA9iSp1mhSr24zTrVnY2kwHy4bkQM_7ZEtPPw5Kid1yqKmFUrM6t7GXwPl7bCB7mcgTi3nlWi0s7ODCGeqDjRX_T1F8ptJDyonuoxRuRU-8HKTTu59yBygE_6D1_jzOD2FQwXxToZ8VBo0kz6V-7PNLQnVCxiOmrV8mzr4EoOvhx_fZircnFaF56lyAQkTw2KLH1wFS_v64ZEKpEt1igrvWQadBoWucWB5LtAatwK9QFL_6MkDaHyNyxoDhLsOeCjU3t_G0RpS2YgIRaA');
  }

  get cookieString(): string {
    return document.cookie || '';
  }

  set cookieString(val: string) {
    document.cookie = val;
  }

  protected cookiesReader(): { [key: string]: any } {
    let lastCookies = {};
    let lastCookieString = '';
    let cookiesArray: string[];
    let cookie: string;
    let i: number;
    let index: number;
    let name: string;
    const currentCookieString = this.cookieString;

    if (currentCookieString !== lastCookieString) {
      lastCookieString = currentCookieString;
      cookiesArray = lastCookieString.split('; ');
      lastCookies = {};
      for (i = 0; i < cookiesArray.length; i++) {
        cookie = cookiesArray[i];
        index = cookie.indexOf('=');
        if (index > 0) {  // ignore nameless cookies
          name = safeDecodeURIComponent(cookie.substring(0, index));
          if (isBlank((lastCookies)[name])) {
            const cookieValue = safeDecodeURIComponent(cookie.substring(index + 1));
            // Ignore cookies stored with value 'undefined'
            if (!isBlank(cookieValue) && cookieValue !== 'undefined') {
              (lastCookies)[name] = cookieValue;
            }
          }
        }
      }
    }
    return lastCookies;
  }

  protected cookiesWriter(): (name: string, value: string | undefined, options?: CookiesOptions) => void {
    return (name: string, value: string | undefined, options?: CookiesOptions) => {
      this.cookieString = this.buildCookieString(name, value, options);
    };
  }

  /* istanbul ignore next */
  private buildCookieString(name: string, value: string | undefined, options?: CookiesOptions): string {
    const opts: CookiesOptions = mergeOptions(this.options, options);
    let expires: any = opts.expires;
    if (isBlank(value)) {
      expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
      value = '';
    }
    if (isString(expires)) {
      expires = new Date(expires);
    }
    let str = encodeURIComponent(name) + '=' + encodeURIComponent((value as string));
    str += opts.path ? ';path=' + opts.path : '';
    str += opts.domain ? ';domain=' + opts.domain : '';
    str += expires ? ';expires=' + expires.toUTCString() : '';
    str += opts.secure ? ';secure' : '';
    const cookiesLength = str.length + 1;
    if (cookiesLength > 4096) {
      console.log(`Cookie \'${name}\' possibly not set or overflowed because it was too
      large (${cookiesLength} > 4096 bytes)!`);
    }
    return str;
  }
}
