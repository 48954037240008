import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@scpc/app.component';
import { config } from '@scpc/app.config';
import { enableProdMode, mergeApplicationConfig } from '@angular/core';
import { getHost } from '@scpc/utils/host.utils';
import { environment } from '@scp-env/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, mergeApplicationConfig(config, {
  providers: [
    { provide: 'HOST', useValue: getHost(window) },
  ],
})).catch((err) => console.error(err));
