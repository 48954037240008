export function parseQuery(queryString: string) {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&');
  for (const item of pairs) {
    const pair = item.split('=');
    if (pair[0]) {
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
  }
  return query;
}
