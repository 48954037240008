export enum WithdrawalRequestStatus {
  WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
  APPROVED_BY_OPERATOR = 'APPROVED_BY_OPERATOR',
  APPROVED_BY_SYSTEM = 'APPROVED_BY_SYSTEM',
  REJECTED_BY_OPERATOR = 'REJECTED_BY_OPERATOR',
  REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
  REJECTED_BY_BANK = 'REJECTED_BY_BANK',
  PROCESSED_BY_OPERATOR = 'PROCESSED_BY_OPERATOR',
  PROCESSED_BY_SYSTEM = 'PROCESSED_BY_SYSTEM',
  REVERSED_BY_OPERATOR = 'REVERSED_BY_OPERATOR'
}
