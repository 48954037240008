import { Directive } from '@angular/core';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';

@Directive()
export abstract class AsyncDialog<ComponentType, DataType, ReturnType = unknown> {

  public constructor(protected dialog: Dialog) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected defaultOptions(data: { isAuthorized: boolean }): DialogConfig {
    return {
      autoFocus: false,
      panelClass: ['scp-filter-with-border', 'scp-filter'],
      backdropClass: 'scp-backdrop',
      maxHeight: 'calc(100% - 20px)',
    };
  }

  abstract open(data: DataType): Promise<DialogRef<ReturnType, ComponentType>>;

}
