import { inject, Injectable } from '@angular/core';
import { AnalyticsData, AnalyticsService } from '@scpc/modules/common/services/analytics/analytics.service';
import { StorageService } from '@scpc/modules/common/services';
import { formatMoneyForExternalService } from '@scpc/utils/money.utils';
import { Game } from '@scpc/modules/games-lobby/model';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { Event, EventMarket, EventOutcome } from '@scpc/modules/sports/dto/event';
import { SportCartSelectionId } from '@scpc/modules/cart/model';

type SportRadarCommand = unknown;

type SportRadarAnalytics = { srtmCommands?: SportRadarCommand[] };

@Injectable({ providedIn: 'root' })
export class SportRadarService extends AnalyticsService<SportRadarAnalytics> {

  private storageService: StorageService = inject(StorageService);

  constructor() {
    super();
  }

  public event(category: string, action: string, label?: string, value?: string, data?: AnalyticsData): void {
    if (!this.isBrowser) {
      return;
    }

    const commands: SportRadarCommand[] | undefined = this.browserGlobals.windowRef().srtmCommands;

    if (commands) {
      let command: SportRadarCommand | null = null;

      switch (category) {
        case 'sign_up':
          command = this.processSignUpEvents(action);
          break;
        case 'sign_in':
          command = this.processSignInEvents(action);
          break;
        case 'deposit':
          command = this.processDepositEvents(action, value, data);
          break;
        case 'game': {
          command = this.processGameEvents(action, data);
          break;
        }
        case 'lucky-numbers': {
          command = this.processLuckyNumbersEvents(action, data);
          break;
        }
        case 'sports': {
          command = this.processSportsEvents(action, data);
          break;
        }
        case 'cart': {
          command = this.processCartEvents(action, data);
          break;
        }
      }

      if (command) {
        commands.push(command);
      }
    }
  }

  private processSignUpEvents(action: string): SportRadarCommand | null {
    switch (action) {
      case 'phone_number_not_registered': {
        return {
          event: 'track.user.registration',
          payload: {
            action: 'start',
            userId: this.storageService.customerId,
          },
        };
      }
      case 'profile_created': {
        return {
          event: 'track.user.registration',
          payload: {
            action: 'complete',
            userId: this.storageService.customerId,
          },
        };
      }
      default: {
        return null;
      }
    }
  }

  private processSignInEvents(action: string): SportRadarCommand | null {
    switch (action) {
      case 'success': {
        return {
          event: 'track.user.login',
          payload: {
            action: 'complete',
            userId: this.storageService.customerId,
          },
        };
      }
      default: {
        return null;
      }
    }
  }

  private processDepositEvents(action: string, value?: string, data?: AnalyticsData): SportRadarCommand | null {
    switch (action) {
      case 'completed': {
        return {
          event: 'track.betting.deposit',
          payload: {
            action: 'created',
            label: value === 'true' ? 'first_deposit' : undefined,
            userId: this.storageService.customerId,
            ...(data.amount
                ? { amount: formatMoneyForExternalService(data.amount), currency: data.amount.currency }
                : {}
            ),
          },
        };
      }
      default: {
        return null;
      }
    }
  }

  private processGameEvents(action: string, data?: AnalyticsData): SportRadarCommand | null {
    switch (action) {
      case 'page_view': {
        return this.createGameEvent(true, data.game);
      }
      case 'launch': {
        return this.createGameEvent(false, data.game);
      }
      default: {
        return null;
      }
    }
  }

  private processLuckyNumbersEvents(action: string, data?: AnalyticsData): SportRadarCommand | null {
    switch (action) {
      case 'page_view': {
        return this.createLuckyNumbersEvent(true, data.draw);
      }
      case 'launch': {
        return this.createLuckyNumbersEvent(false, data.draw);
      }
      default: {
        return null;
      }
    }
  }

  private processSportsEvents(action: string, data?: AnalyticsData): SportRadarCommand | null {
    switch (action) {
      case 'page_view': {
        return this.createSportsEvent(true, data.event);
      }
      case 'add_to_cart': {
        return this.createSportsEvent(false, data.event, data.selection);
      }
      default: {
        return null;
      }
    }
  }

  private createGameEvent(view: boolean, game: Game): unknown {
    return {
      event: view ? 'srt.retargeting' : 'srt.betslip',
      payload: {
        casinoEvent: {
          game: {
            id: game.gameId,
            name: game.name,
            category: game.product,
            image: window.origin + (game.icon?.url || `/assets/common/${game.product === 'SLOTS' ? 'slots' : 'live-games'}/${game.provider.type.toLowerCase()}/${game.gameId}.jpg`),
            provider: {
              id: game.provider.type,
              game_id: game.originalGameId,
            },
          },
          event: {
            type: 'page_view',
          },
          version: '1-0-0',
        },
      },
    };
  }

  private createLuckyNumbersEvent(view: boolean, draw: Draw): unknown {
    return {
      event: view ? 'srt.retargeting' : 'srt.betslip',
      payload: {
        casinoEvent: {
          game: {
            id: draw.drawId,
            name: draw.drawName,
            category: 'LUCKY_NUMBERS',
            image: window.origin + '/assets/common/lucky-numbers/draws/' + draw.drawId + '.svg',
            provider: {
              id: 'LUCKY_NUMBERS',
              game_id: draw.drawId,
            },
          },
          event: {
            type: 'page_view',
          },
          version: '1-0-0',
        },
      },
    };
  }

  private createSportsEvent(view: boolean, event: Event, selection?: SportCartSelectionId): unknown | null {
    if (!event || !!event.season || !event.markets.length || (!view && !selection)) {
      return null;
    }

    const market: EventMarket | undefined = (
      selection
        ? event.markets.filter((m: EventMarket): boolean => m.marketUniqId === selection.marketUniqId)
        : event.markets
    ).filter((m: EventMarket): boolean => [
      '1',   // 1x2
      '2',   // To qualify
      '113', // Overtime - 1x2
      '123', //	Penalty shootout - winner
      '186', // Winner
      '219', // Winner (incl. overtime)
      '251', // Winner (incl. extra innings)
      '340', // Winner (incl. super over)
    ].includes(m.id))
      .sort((a, b) => Number(a.id) - Number(b.id))[0];

    if (!market) {
      return null;
    }

    const odds_home: string | undefined = market.outcomes.find((o: EventOutcome) => ['1', '4'].includes(o.id))?.odds.toString();
    const odds_draw: string | undefined = market.outcomes.find((o: EventOutcome) => ['2'].includes(o.id))?.odds.toString();
    const odds_away: string | undefined = market.outcomes.find((o: EventOutcome) => ['3', '5'].includes(o.id))?.odds.toString();

    if (!odds_home || !odds_away) {
      return null;
    }

    return {
      event: view ? 'srt.retargeting' : 'srt.betslip',
      payload: {
        sportsEvent: {
          match_id: event.id,
          sr_match_id: event.id,
          team_home_name: event.homeTeam.name,
          team_away_name: event.awayTeam.name,
          scheduled: new Date(event.scheduleTime).toISOString(),
          status: event.matchProperties.status,
          tournament_name: event.tournament.name,
          sport: event.sport.name.toLowerCase().replace(/ /g, '_'),
          odds_home,
          odds_draw: odds_draw || '',
          odds_away,
          language: 'en',
        },
      },
    };
  }

  private processCartEvents(action: string, data?: AnalyticsData): SportRadarCommand | null {
    switch (action) {
      case 'bet_placed': {
        return {
          event: 'track.betting.betPlaced',
          payload: {
            userId: this.storageService.customerId,
            transactionId: data.purchase.transactionId,
            amount: data.purchase.amount,
            currency: data.purchase.currency,
            contentIds: data.purchase.contentIds,
          },
        };
      }
      default: {
        return null;
      }
    }
  }

}
