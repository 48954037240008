import { Injectable } from '@angular/core';
import { TourService } from 'ngx-ui-tour-core';
import { TourTippyStepOption } from '@scpc/modules/common/tour/tippy-step-option';

@Injectable()
export class TourTippyService extends TourService<TourTippyStepOption> {

  public get index(): number {
    if (!this.currentStep) {
      return -1;
    }
    return Math.max(this.steps.indexOf(this.currentStep), 0);
  }

}
