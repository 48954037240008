<div class='scp-header-cookies'>
  <a [routerLink]='"/privacy-policy"'
     [fragment]='"12-cookies"'>
    {{ 'COOKIES_DESCRIPTION' | translate }}
    <span>{{ 'Read' | translate }}</span>
  </a>
  <scp-btn id='accept'
           type='2'
           width='110'
           height='28'
           [hasMarginTop]='false'
           text='ACCEPT'
           (click)='acceptCookies()'>
  </scp-btn>
</div>
