import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonComponent } from '@scpc/modules/common/components/button/button.component';
import { SmartBannerService } from '@scpc/modules/common/components/smart-banner/smart-banner.service';
import { SmartBannerConfig } from '@scpc/dto';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'scp-smart-banner',
  templateUrl: './smart-banner.component.html',
  styleUrls: ['./smart-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ButtonComponent,
  ],
})
export class SmartBannerComponent implements OnChanges {

  @Input()
  public logo: string;

  @Input()
  public config: SmartBannerConfig;

  @Input()
  public siteName: string;

  @Input()
  public isAuthorized: boolean;

  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  protected icon: string;
  protected title: string;
  protected url: string;

  constructor(private readonly smartBannerService: SmartBannerService,
              @Inject(DOCUMENT) private readonly document: Document) {
  }

  public ngOnChanges(): void {
    if (this.smartBannerService.isAllowed(this.config, this.isAuthorized)) {
      const platform: string = this.smartBannerService.getPlatform();
      if (this.isAuthorized) {
        if (platform === 'IPHONE') {
          this.title = `${this.siteName} App for iOS AppStore`;
          this.icon = 'platform-app-store';
          this.url = this.config.iosUrl;
        } else if (platform === 'ANDROID') {
          this.title = `${this.siteName} App for Android`;
          this.icon = 'platform-android';
          this.url = this.config.androidUrl;
        } else if (platform === 'HUAWEI') {
          this.title = `${this.siteName} App for AppGallery`;
          this.icon = 'platform-app-gallery';
          this.url = this.config.androidUrl;
        } else if (platform === 'SAMSUNG') {
          this.title = `${this.siteName} App for Galaxy Store`;
          this.icon = 'platform-galaxy-store';
          this.url = this.config.androidUrl;
        }
      } else {
        this.title = `${this.siteName} Mobile App`;
        this.icon = '';
        this.url = this.config.notAuthorizedUrl;
      }
      this.document.documentElement.setAttribute('banner', this.smartBannerService.isSupported() && (/\biPhone\b/.test(this.document.defaultView.navigator.userAgent) || /\bAndroid\b/.test(this.document.defaultView.navigator.userAgent)).toString());
    }
  }

  protected hide(): void {
    if (this.isAuthorized) {
      this.smartBannerService.updateCloseTime();
      this.close.next();
    }
  }

}
