export const parseJWT = <Payload extends Record<string, string | number>, Header extends Record<string, string>>(
  jwt: string
): { payload: Payload; header: Header } => {
  if (typeof jwt !== 'string') {
    throw new Error('Invalid token specified');
  }
  try {
    /* istanbul ignore if */
    if (typeof atob === 'undefined') {
      const header = JSON.parse(Buffer.from(jwt.split('.')[0], 'base64').toString('binary'));
      const payload = JSON.parse(Buffer.from(jwt.split('.')[1], 'base64').toString('binary'));
      return {header, payload};
    } else {
      const header = JSON.parse(atob(jwt.split('.')[0]));
      const payload = JSON.parse(atob(jwt.split('.')[1]));
      return {header, payload};
    }
  } catch (e) {
    throw new Error('Invalid token specified: ' + e.message);
  }
};
