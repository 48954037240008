import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterService {
  private previous: string | null = null;
  private current: string | null = null;

  private first: number = 2;

  constructor(router: Router) {
    this.current = router.url;
    router.events.subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        if (this.first > 0) {
          this.first = Math.min(--this.first);
        }
        this.previous = this.current;
        this.current = event.url;
      }
    });
  }

  public get currentUrl(): string {
    return this.current;
  }

  public get previousUrl(): string | null {
    return this.previous;
  }

  public get isFirst(): boolean {
    return this.first > 0;
  }

}
