/* istanbul ignore file */

import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {

  public getTranslation(): Observable<unknown> {
    const catchErr = catchError(res => {
      console.error('Something went wrong for the following translation file');
      console.error(res.message);
      return of({});
    });
    return forkJoin([
      from(import('../../../../assets/i18n/common.en.json')).pipe(map(a => a.default), catchErr),
      from(import('../../../../assets/i18n/markets.en.json')).pipe(map(a => a.default), catchErr),
    ]).pipe(map(response => this.mergeDeep(...response)));
  }

  private isObject(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  private mergeDeep(target: any, source: any): any {
    const output = Object.assign({}, target);

    if (!this.isObject(target)) {
      return this.mergeDeep({}, source);
    }

    if (this.isObject(target) && this.isObject(source)) {
      Object.keys(source).forEach((key: any) => {
        if (this.isObject(source[key])) {
          if (!(key in target)) {
            Object.assign(output, { [key]: source[key] });
          } else {
            output[key] = this.mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    return output;
  }

}
