@if (title) {
  <div class='scp-smart-banner'>
    <div class='scp-smart-banner__logo'>
      <img [src]='logo' alt=''>
    </div>
    @if (isAuthorized) {
      <button class='scp-smart-banner__close' (click)='hide()' aria-label='Close smart banner'>
        <img src='/assets/images/svg/close.svg' width='10' height='10' alt=''>
      </button>
    }
    <div>
      <div class='scp-smart-banner__title'>{{ title }}</div>
      <div class='scp-smart-banner__description'>{{ config.description }}</div>
    </div>
    <scp-btn type='14'
             height='36'
             class='scp-smart-banner__action'
             text='Download {{siteName}} App'
             [icon]='icon'
             target='_blank'
             [url]='url'
             (click)='hide()'
             [hasMarginTop]='false'>
    </scp-btn>
  </div>
}
