import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

import { CookiesOptions } from './cookies-options';
import { mergeOptions } from './utils';

export const COOKIES_OPTIONS = new InjectionToken('COOKIES_OPTIONS');

@Injectable()
export class CookiesOptionsService {

  public options: CookiesOptions;

  constructor(@Optional() @Inject(COOKIES_OPTIONS) options: CookiesOptions,
              private injector: Injector) {
    this.options = mergeOptions({
      path: this.injector.get(APP_BASE_HREF, '/'),
      domain: null,
      expires: null,
      secure: false
    }, options);
  }

}
