import { getElementsByClassName, getFirstElementByTagName } from '@scpc/utils/dom.utils';
import { ScpTour } from '@scpc/modules/common/services/scp.tour.service';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';


export const getBodyFN =  /* istanbul ignore next */ () => document.body;
export const getDrawsRootFN =  /* istanbul ignore next */ () => getFirstElementByTagName(document, 'scp-draws');
export const getDrawRootFN =  /* istanbul ignore next */() => getFirstElementByTagName(document, 'scp-draw');
export const getDrawSelectionRootFN = /* istanbul ignore next */ () => getFirstElementByTagName(document, 'mat-sidenav-content');
export const getDrawResultRootFN =  /* istanbul ignore next */() => getFirstElementByTagName(document, 'scp-draw-result');
export const getDrawSelectionSimilarRootFN =  /* istanbul ignore next */() => getElementsByClassName(document, 'swiper selected');
export const ROOT_PAGE = '^/lucky-numbers(|/rapid|/popular)$';
export const DRAW_PAGE = '^/lucky-numbers/[a-z0-9_]*$';
export const DRAW_SELECTIONS_PAGE = '^/lucky-numbers/[a-z0-9_]*/selections$';
export const DRAW_RESULTS_PAGE = '^/lucky-numbers/[a-z0-9_]*/results$';

function isCreateLuckyNumbersTour(path: string): boolean {
  return path.startsWith('/lucky-numbers') && !path.endsWith('/weekly-draws-schedule') && !path.includes('results');
}

function isCreateLuckyNumbersComboBettingTourSteps(path: string): boolean {
  return isCreateLuckyNumbersTour(path);
}

function isCreateLuckyNumbersHotColdTourSteps(path: string): boolean {
  return isCreateLuckyNumbersTour(path);
}

function isCreateLuckyNumbersResultsTourSteps(path: string): boolean {
  return path.startsWith('/lucky-numbers') &&
    !path.endsWith('/weekly-draws-schedule') &&
    !path.includes('selections') &&
    !path.match('^/lucky-numbers/(rapid|popular)/results$') &&
    path !== '/lucky-numbers/results';
}

function createLuckyNumbersResultsSelectionTourSteps(path: string): boolean {
  return path.startsWith('/lucky-numbers') && path.endsWith('/selections');
}

export function getTours(route: string, document: Document, draw: () => Draw): ScpTour[] {
  const path = route.split('?')[0];
  const tours: ScpTour[] = [];
  if (isCreateLuckyNumbersTour(path)) {
    tours.push({
      name: 'LN',
      getTourSteps: async () => import('./tours.1').then(module => module.createLuckyNumbersTourSteps(document, draw()))
    });
  }
  if (isCreateLuckyNumbersComboBettingTourSteps(path)) {
    tours.push({
      name: 'LNCB',
      getTourSteps: async () => import('./tours.2').then(module => module.createLuckyNumbersComboBettingTourSteps(document, draw()))
    });
  }
  if (isCreateLuckyNumbersHotColdTourSteps(path)) {
    tours.push({
      name: 'LNHC',
      getTourSteps: async () => import('./tours.3').then(module => module.createLuckyNumbersHotColdTourSteps(document, draw()))
    });
  }
  if (isCreateLuckyNumbersResultsTourSteps(path)) {
    tours.push({
      name: 'LNR',
      getTourSteps: async () => import('./tours.4').then(module => module.createLuckyNumbersResultsTourSteps(document, draw()))
    });
  }
  if (createLuckyNumbersResultsSelectionTourSteps(path)) {
    tours.push({
      name: 'LNRS',
      getTourSteps: async () => import('./tours.5').then(module => module.createLuckyNumbersResultsSelectionTourSteps(document, draw()))
    });
  }
  if (path === '/lucky-numbers' || (draw()?.similar?.length) && path.startsWith('/lucky-numbers/' + draw().drawId)) {
    tours.push({
      name: 'LNSD',
      getTourSteps: async () => import('./tours.6')
        .then(module => module.createLuckyNumbersAdditionalDrawsTourSteps(document, (
          /* istanbul ignore next */ draw()?.similar?.length
          ? draw()
          : { drawId: 'uk_49s_lunch' } as Draw))
        )
    });
  }
  return tours;
}
