import { formatCurrency, getCurrencySymbol, getNumberOfCurrencyDigits } from '@angular/common';
import { Money } from '@scpc/dto';

export function toCoins(amount: string, currency: string): number {
  return Number(amount.replace(/,/g, '')) * getNumberOfDigits(currency);
}

export function formatMoney(value: Money, symbol: boolean = true, coins: boolean = true, minimize: boolean = false): string {
  const d: number = getNumberOfDigits(value.currency);
  const minimized: boolean = minimize && value.value >= 100000;
  return formatCurrency(
    minimized ? value.value / d / 1000 : value.value / d,
    'en',
    symbol ? getCurrencySymbol(value.currency, 'narrow') : '',
    value.currency,
    !minimized && (coins || !Number.isInteger(value.value / d)) ? undefined : minimized ? '1.0' : '1.0-0',
  ) + (minimized ? 'k' : '');
}

export function formatMoneyForExternalService(value: Money): string {
  const digits: number = getNumberOfDigits(value.currency);
  return (value.value / digits).toFixed(getNumberOfCurrencyDigits(value.currency));
}

function getNumberOfDigits(currency: string): number {
  return Math.pow(10, getNumberOfCurrencyDigits(currency));
}

export function floor(amount: Money): Money {
  const digits: number = getNumberOfDigits(amount.currency);
  const value: number = Math.floor(amount.value / digits) * digits;
  return { value, currency: amount.currency };
}

export function min(currency: string): Money {
  const digits: number = getNumberOfDigits(currency);
  return { value: digits, currency };
}
