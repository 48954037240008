import { inject, Injectable } from '@angular/core';
import { Collection } from 'ngx-collection';
import { BonusProgramCategory, BonusProgramOffer, BonusProgramOfferStatus } from '@scpc/dto/bonus-program-offer';
import { concat, Observable, of, switchMap, toArray } from 'rxjs';
import { WagerType } from '@scpc/dto';
import { AuthenticationService, ScpService } from '@scpc/modules/common/services';
import { catchError } from 'rxjs/operators';

export function isSameSelectionId(o1: BonusProgramOffer, o2: BonusProgramOffer): boolean {
  return o1.id === o2.id;
}

@Injectable({ providedIn: 'root' })
export class SportsFreeBetsCollection extends Collection<BonusProgramOffer> {

  private scpService: ScpService = inject(ScpService);
  private authenticationService: AuthenticationService = inject(AuthenticationService);

  constructor() {
    super({
      allowFetchedDuplicates: true,
      comparator: isSameSelectionId,
      errReporter: console.error,
    });
  }

  public initialize(): Observable<void> {
    return this.authenticationService.isAuthorized().pipe(switchMap((isAuthorized: boolean): Observable<void> => {
      if (isAuthorized) {
        return concat(...[
          this.deleteMany({ items: this.$items() }),
          this.createMany({
            request: this.scpService.getBonusOffers(
              0,
              [
                BonusProgramOfferStatus.ACTIVE,
                BonusProgramOfferStatus.ACCEPTED,
              ], false, true,
              [
                BonusProgramCategory.SEGMENT_FREE_BET,
                BonusProgramCategory.SEGMENT_FREE_BET_WITH_CONDITIONALS,
              ],
              [
                WagerType.WAGER,
              ], true),
          })]).pipe(toArray(), switchMap(() => of(void 0)), catchError(() => of(void 0)));
      }
      return of(void 0);
    }));
  }

}
