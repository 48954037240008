@if (title) {
  <div class='scp-page-title-border' [class.hidden]='isHiddenTitle'>
    @if (back) {
      <a [routerLink]='back' [replaceUrl]='true' mat-icon-button class='scp-page-back'>
        <img src='/assets/images/svg/back.svg' width='16' height='16' alt='' loading='lazy' />
      </a>
    }
    <h1 class='scp-page-title' [class.small]='back'>{{ title | translate: { siteName: siteName } }}</h1>
  </div>
}
