import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, skip } from 'rxjs/operators';
import { AnalyticsService } from '@scpc/modules/common/services/analytics/analytics.service';

type BigoAnalytics = { bge: (type: string, event: string) => void };

@Injectable({ providedIn: 'root' })
export class BigoService extends AnalyticsService<BigoAnalytics> {

  private readonly router: Router = inject(Router);

  constructor() {
    super();
    if (this.isBrowser) {
      /* istanbul ignore next */
      this.router.events
        .pipe(
          filter((event: NavigationEnd) => event instanceof NavigationEnd),
          skip(1),
        )
        .subscribe(() => this.event('page_view', 'page_view'));
    }
  }

  public event(category: string, action: string, label?: string, value?: string): void {
    const windowRef: BigoAnalytics = this.browserGlobals.windowRef();
    if (this.isBrowser && windowRef.bge) {
      if (category === 'deposit' && action === 'completed' && value === 'true') {
        windowRef.bge('event', 'ec_purchase');
      } else if (category === 'sign_up' && action === 'profile_created') {
        windowRef.bge('event', 'ec_register');
      } else if (category === 'page_view') {
        windowRef.bge('event', 'page_view');
      }
    }
  }

}
