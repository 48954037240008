import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TourHotkeyListenerComponent, TourState } from 'ngx-ui-tour-core';
import { TourStepTippyTemplateService } from '@scpc/modules/common/tour/tippy-tour-step-template.service';
import { TourTippyService } from '@scpc/modules/common/tour/tippy-tour.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'scp-tour-step-template',
  styleUrls: ['./tippy-tour-step-template.component.scss'],
  templateUrl: './tippy-tour-step-template.component.html',
})
export class TourStepTemplateComponent extends TourHotkeyListenerComponent implements AfterContentInit {

  @ViewChild('tourStep', { read: TemplateRef, static: true })
  public tourStepTemplate: TemplateRef<any>;

  constructor(private readonly tourStepTemplateService: TourStepTippyTemplateService,
              public readonly tourService: TourTippyService,
              public readonly translateService: TranslateService,
              public readonly domSanitizer: DomSanitizer,
              public readonly zone: NgZone) {
    super(tourService);
  }

  public onEscapeKey() {
    if (this.tourService.getStatus() === TourState.ON && this.tourService.isHotkeysEnabled()) {
      this.tourService.pause();
    }
  }

  public onArrowLeftKey(): void {
  }

  public ngAfterContentInit(): void {
    this.tourStepTemplateService.template = this.tourStepTemplate;
  }

  public end(event: MouseEvent) {
    event.stopPropagation();
    this.zone.run(() => this.tourService.end());
  }

  public next(event: MouseEvent) {
    event.stopPropagation();
    this.zone.run(() => this.tourService.next());
  }

  public pause(event: MouseEvent) {
    event.stopPropagation();
    this.zone.run(() => this.tourService.pause());
  }

}
