import { Inject, Injectable, Optional } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LayoutService, StorageService } from '@scpc/modules/common/services';

@Injectable({ providedIn: 'root' })
export class SwInterceptor implements HttpInterceptor {

  private readonly source: string;

  constructor(
    storageService: StorageService,
    @Inject('HOST') private readonly host: string,
    @Optional() @Inject('USER_AGENT') userAgent: string,
    private readonly layoutService: LayoutService,
  ) {
    this.source = storageService.device || this.layoutService.getDevice(userAgent);
  }

  public intercept(request: HttpRequest<object>, next: HttpHandler) {
    const { url } = request;
    if (url.includes('/api') || url.includes('/cms/') || url.includes('widgets')) {
      /* istanbul ignore else */
      if (!url.startsWith('http')) {
        return next.handle(request.clone({
          url: this.host + url,
          headers: request.headers
            .set('ngsw-bypass', 'true')
            .set('x-source', this.source),
        }));
      } else {
        return next.handle(request.clone({
          headers: request.headers
            .set('ngsw-bypass', 'true')
            .set('x-source', this.source),
        }));
      }
    }
    return next.handle(request);
  }

}
