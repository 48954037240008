import { ElementRef, Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { AccessToken, Balance, DepositType, Money, WithdrawalConfiguration } from '@scpc/dto';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Customer, CustomerBank } from '@scpc/dto/customer';
import { parseJWT } from '@scpc/utils/jwt.utils';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BonusProgramOffer } from '@scpc/dto/bonus-program-offer';
import { DEPOSIT_RESULT } from '@scpc/modules/common/guards/activation.guard';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { CookiesService } from '@scpc/modules/cookies/cookies.service';

export interface InputValue {
  key: string;
  value: string | null;
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  private _username: string | null = null;
  private _phone: string | null = null;
  private _phoneRegistered = false;
  private _forgotPasswordOTP: string | null = null;
  private _forgotPasswordOTPVerified = false;
  private _beforeKYCUrl: string | null = null;
  private _depositResult: {
    type: DepositType;
    first: boolean;
    status: string;
    amount?: Money;
    reference?: string;
  } | null = null;
  private _balance: Subject<Balance> = new BehaviorSubject<Balance>(null);
  private _customerBanks: Subject<CustomerBank[]> = new BehaviorSubject<CustomerBank[]>([]);
  private _customer: Subject<Customer> = new BehaviorSubject<Customer>(null);
  private _zendeskToken: Subject<string> = new BehaviorSubject<string>(null);
  private _bonusProgramOffers: Subject<BonusProgramOffer[]> = new BehaviorSubject<BonusProgramOffer[]>(null);
  private _withdrawalConfiguration: Subject<WithdrawalConfiguration> = new BehaviorSubject<WithdrawalConfiguration>(
    null,
  );
  private _inputValue: Subject<InputValue> = new BehaviorSubject<InputValue>({ key: '', value: null });
  private _signUpGovernmentId: string | null = null;
  private _signUpSkipGovernmentId = false;
  private _signUpOTP: string = null;
  private _signUpOTPVerified = false;
  private _signUpFlow = false;
  private _signUpLock = false;
  private _signUpEKYCOptions: string[] = [];
  private _scrollbar: Subject<ElementRef<HTMLDivElement>> = new BehaviorSubject<ElementRef<HTMLDivElement>>(null);
  private _offers: Subject<number> = new BehaviorSubject<number>(0);

  private isBrowser = isPlatformBrowser(this.platformId);

  constructor(private readonly cookiesService: CookiesService,
              private readonly transferState: TransferState,
              @Inject(PLATFORM_ID) private readonly platformId: string,
              @Inject(DOCUMENT) private readonly document: Document) {
  }

  public static getNextYearDate(): Date {
    const date = new Date(Date.now());
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }

  public static getNextMonthDate(): Date {
    const date = new Date(Date.now());
    date.setMonth(date.getMonth() + 1);
    return date;
  }

  public savePhoneAndUsername(phone: string, username: string): void {
    this._phone = phone;
    this._username = username;
    this._balance.next(null);
  }

  public get phone(): string | null {
    return this._phone;
  }

  public set phone(phone: string | null) {
    this._phone = phone;
  }

  public set phoneRegistered(isPhoneRegistered: boolean) {
    this._phoneRegistered = isPhoneRegistered;
  }

  public get phoneRegistered(): boolean {
    return this._phoneRegistered;
  }

  public set forgotPasswordOTPVerified(forgotPasswordOTPVerified: boolean) {
    this._forgotPasswordOTPVerified = forgotPasswordOTPVerified;
  }

  public get forgotPasswordOTPVerified(): boolean {
    return this._forgotPasswordOTPVerified;
  }

  public set forgotPasswordOTP(otp: string) {
    this._forgotPasswordOTP = otp;
  }

  public get forgotPasswordOTP(): string | null {
    return this._forgotPasswordOTP;
  }

  public get username(): string | null {
    return this._username;
  }

  public set username(username: string | null) {
    this._username = username;
  }

  public isAuthorised(): boolean {
    return !!this.getCookie('access_token');
  }

  public get customerId(): string {
    return this.getCookie('customerId');
  }

  public getZendeskToken(): Observable<string> {
    return this._zendeskToken;
  }

  public setZendeskToken(zendeskToken: string | null): void {
    this._zendeskToken.next(zendeskToken);
  }

  public get accessToken(): string {
    return this.getCookie('access_token');
  }

  public get refreshToken(): string {
    return this.getCookie('refresh_token');
  }

  public get accessTokenExpiresAt(): string {
    return this.getCookie('expires_at');
  }

  public saveAccessToken(accessToken: AccessToken, customerId?: string): void {
    this.deleteCookie('customerId');
    this.deleteCookie('access_token');
    this.deleteCookie('refresh_token');
    this.deleteCookie('expires_at');
    const date = StorageService.getNextYearDate();
    this.cookiesService.put(
      'customerId',
      (parseJWT(accessToken.access_token).payload.customerId as string) || customerId,
      {
        expires: date,
      },
    );
    this.cookiesService.put('access_token', accessToken.access_token, { expires: date });
    this.cookiesService.put('refresh_token', accessToken.refresh_token, { expires: date });
    this.cookiesService.put('expires_at', accessToken.expires_at, { expires: date });
  }

  public deleteAccessToken(): void {
    this.deleteCookie('customerId');
    this.deleteCookie('access_token');
    this.deleteCookie('refresh_token');
    this.deleteCookie('expires_at');
    this.setZendeskToken(null);
  }

  public saveDepositResult(depositResult: {
    type: DepositType;
    status: string;
    first: boolean;
    amount?: Money;
    reference?: string;
    urlForRedirect?: string;
  }): void {
    this._depositResult = depositResult;
    this.transferState.set(DEPOSIT_RESULT, depositResult);
  }

  public getDepositResult(): {
    type: DepositType;
    status: string;
    first: boolean;
    amount?: Money;
    reference?: string;
    urlForRedirect?: string;
  } | null {
    return this._depositResult || this.transferState.get(DEPOSIT_RESULT, undefined as any);
  }

  public deleteDepositResult(): void {
    this._depositResult = null;
    if (this.isBrowser) {
      this.transferState.remove(DEPOSIT_RESULT);
    }
  }

  public isCookiesAccepted(): boolean {
    return this.getCookie('cookies-accepted') === 'true';
  }

  public saveCookiesAccepted(): void {
    this.cookiesService.put('cookies-accepted', 'true', {
      expires: StorageService.getNextYearDate(),
    });
  }

  public getCurrency(): string {
    return 'ZAR';
  }

  public get balance(): Observable<Balance> {
    return this._balance;
  }

  public setBalance(balance: Balance): void {
    this._balance.next(balance);
  }

  public set beforeKYCUrl(url: string | null) {
    this._beforeKYCUrl = url;
  }

  public get beforeKYCUrl(): string | null {
    return this._beforeKYCUrl;
  }

  public getCustomerBanks(): Observable<CustomerBank[]> {
    return this._customerBanks;
  }

  public setCustomerBanks(banks: CustomerBank[]): void {
    this._customerBanks.next(banks);
  }

  public getCustomer(): Observable<Customer> {
    return this._customer;
  }

  public setCustomer(customer: Customer): void {
    this._customer.next(customer);
  }

  public getBonusProgramOffers(): Observable<BonusProgramOffer[]> {
    return this._bonusProgramOffers;
  }

  public setBonusProgramOffers(offers: BonusProgramOffer[]): void {
    this._bonusProgramOffers.next(offers);
  }

  public getWithdrawalConfiguration(): Observable<WithdrawalConfiguration | null> {
    return this._withdrawalConfiguration;
  }

  public setWithdrawalConfiguration(config: WithdrawalConfiguration): void {
    this._withdrawalConfiguration.next(config);
  }

  public savePreviousUrl(url: string): void {
    this.cookiesService.put('previous-url', url);
  }

  public deletePreviousUrl(): void {
    this.deleteCookie('previous-url');
  }

  public getPreviousUrl(): string {
    return this.getCookie('previous-url');
  }

  public get signUpGovernmentId(): string | null {
    return this._signUpGovernmentId;
  }

  public set signUpGovernmentId(governmentId: string | null) {
    this._signUpGovernmentId = governmentId;
  }

  public get signUpOTP(): string | null {
    return this._signUpOTP;
  }

  public set signUpOTP(otp: string | null) {
    this._signUpOTP = otp;
  }

  public get signUpSkipGovernmentId(): boolean {
    return this._signUpSkipGovernmentId;
  }

  public set signUpSkipGovernmentId(skip: boolean) {
    this._signUpSkipGovernmentId = coerceBooleanProperty(skip);
  }

  public get signUpOTPVerified(): boolean {
    return this._signUpOTPVerified;
  }

  public set signUpOTPVerified(verified: boolean) {
    this._signUpOTPVerified = coerceBooleanProperty(verified);
  }

  public get signUpFlow(): boolean {
    return this._signUpFlow;
  }

  public set signUpFlow(signUpFlow: boolean) {
    this._signUpFlow = coerceBooleanProperty(signUpFlow);
  }

  public get signUpLock(): boolean {
    return this._signUpLock;
  }

  public set signUpLock(signUpLock: boolean) {
    this._signUpLock = coerceBooleanProperty(signUpLock);
  }

  public get signUpEKYCOptions(): string[] {
    return this._signUpEKYCOptions;
  }

  public set signUpEKYCOptions(options: string[]) {
    this._signUpEKYCOptions = options;
  }

  public getInputValue(): Observable<InputValue | null> {
    return this._inputValue;
  }

  public setInputValue(value: InputValue | null): void {
    this._inputValue.next(value);
  }

  public getScrollbar(): Observable<ElementRef<HTMLDivElement> | null> {
    return this._scrollbar;
  }

  public setScrollbar(scrollbar: ElementRef<HTMLDivElement>) {
    this._scrollbar.next(scrollbar);
  }

  public setOffers(offers: number) {
    this._offers.next(offers);
  }

  public getOffers(): Observable<number> {
    return this._offers;
  }

  public get device(): string | null {
    return this.getCookie('device');
  }

  public set device(device: string) {
    if (device) {
      this.cookiesService.put('device', device, {
        expires: StorageService.getNextYearDate(),
      });
    } else {
      this.deleteCookie('device');
    }
  }

  public set smartBanner(date: Date) {
    if (date) {
      this.cookiesService.put('smart-banner', date.getTime().toString(), {
        expires: StorageService.getNextYearDate(),
      });
    } else {
      this.deleteCookie('smart-banner');
    }
  }

  public get smartBanner() {
    const date: string | null = this.getCookie('smart-banner');
    return date ? new Date(Number(date)) : null;
  }

  private getCookie(name: string): string {
    const value = this.cookiesService.get(name);
    if (value === 'undefined') {
      return null;
    }
    return value;
  }

  private deleteCookie(name: string): void {
    this.cookiesService.remove(name);
  }

}
