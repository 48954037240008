import { Money } from '@scpc/dto/money';
import { LuckyNumbersWager } from '@scpc/modules/lucky-numbers/dto/lucky-numbers.wager';
import { BonusProgram, BonusProgramOffer } from '@scpc/dto/bonus-program-offer';

export enum PromotionType {
  GENERAL = 'GENERAL',
  SEGMENT = 'SEGMENT'
}

export interface PromotionStats {

  all: number;
  today: number;
  bonusProgramOfferId?: number;

}

export interface PromotionEvent {

  eventId: string;
  eventName: string;
  marketType: string;
  outcomeType: string;
  combinations: number;
  stake: number;

}

export interface PromotionError {
  type: string;
  value?: string;
}

export interface Promotion {

  id: number;
  type: string;
  name: string;
  description: string;
  image?: string;
  imageOverlay?: string;
  terms?: string;
  discount: Money;
  startedAt: Date | number;
  endedAt?: Date | number;
  wagerType: string;
  perDayTimes: number;
  totalTimes?: (number | null);
  events: PromotionEvent[];
  stats?: PromotionStats;
  code?: string;
  wager: string;
  errors?: PromotionError[];
  luckyNumbersWagers?: LuckyNumbersWager[];
  label?: string;
  maxPayout?: string;

}

export interface Ad {
  id: number;
  name: string;
  description: string;
  image: {
    url: string,
    width: number;
    height: number;
  };
  content: string;
  contentNoAuth: string;
  action: {
    title: string;
    url: string;
  };
  actionNoAuth: {
    title: string;
    url: string;
  };
  terms?: string;
  products: {
    name: string,
    type: string
  }[];
  startedAt: Date | number | string;
  endedAt?: Date | number | string;
  zone1: {
    type: string;
    title: string;
    value: string;
  };
  zone2: {
    type: string;
    title: string;
    value: string;
  };
}

export interface PromotionItem {
  type: 'PROMOTION' | 'AD' | 'BONUS_PROGRAM' | 'BONUS_PROGRAM_OFFER',
  product: {
    type: string;
    name: string;
  };
  products: string[]
  promotion?: Promotion;
  ad?: Ad;
  bonusProgram?: BonusProgram;
  bonusProgramOffer?: BonusProgramOffer;
}

export interface PromotionLobbyItem {
  type: string;
  title: string;
  items: PromotionItem[];
}

export interface PromotionLobby {
  products: string[];
  items: PromotionLobbyItem[];
}
