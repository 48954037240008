import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from '@scpc/modules/common/services';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenGuard implements CanActivate {

  constructor(private readonly authenticationService: AuthenticationService) {
  }

  public canActivate(): Observable<boolean> {
    return this.authenticationService.tryToRefreshToken()
      .pipe(map(() => true), catchError(() => of(true)));
  }

}
