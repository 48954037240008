export enum DepositType {
  ATM = 'ATM',
  ATM_ABSA = 'ATM_ABSA',
  ATM_CAPITEC = 'ATM_CAPITEC',
  ATM_FNB = 'ATM_FNB',
  ATM_NEDBANK = 'ATM_NEDBANK',
  ATM_STANDARD_BANK = 'ATM_STANDARD_BANK',

  BANK_DEPOSIT = 'BANK_DEPOSIT',
  BANK_DEPOSIT_ABSA = 'BANK_DEPOSIT_ABSA',
  BANK_DEPOSIT_CAPITEC = 'BANK_DEPOSIT_CAPITEC',
  BANK_DEPOSIT_FNB = 'BANK_DEPOSIT_FNB',
  BANK_DEPOSIT_NEDBANK = 'BANK_DEPOSIT_NEDBANK',
  BANK_DEPOSIT_STANDARD_BANK = 'BANK_DEPOSIT_STANDARD_BANK',

  EFT = 'EFT',
  EFT_ABSA = 'EFT_ABSA',
  EFT_CAPITEC = 'EFT_CAPITEC',
  EFT_FNB = 'EFT_FNB',
  EFT_NEDBANK = 'EFT_NEDBANK',
  EFT_STANDARD_BANK = 'EFT_STANDARD_BANK',

  SNAP_SCAN = 'SNAP_SCAN',
  CREDIT_TOKEN = 'CREDIT_TOKEN',
  CREDIT_OR_DEBIT_CARD = 'CREDIT_OR_DEBIT_CARD',
  BONUS = 'BONUS',
  OZOW = 'OZOW',
  OZOW_CAPITEC_PAY = 'OZOW_CAPITEC_PAY',
  ONE_FOR_YOU = 'ONE_FOR_YOU',
  ONE_VOUCHER = 'ONE_VOUCHER',
  ONE_VOUCHER_DIRECT = 'ONE_VOUCHER_DIRECT',
  OTT_VOUCHER = 'OTT_VOUCHER',
  ZAPPER = 'ZAPPER',
  ACOIN = 'ACOIN',
  BLU_VOUCHER = 'BLU_VOUCHER',
  KAZANG = 'KAZANG',
  APPLE_PAY = 'APPLE_PAY',
  SCODE = 'SCODE'
}
