export function minutesToMilliseconds(m: number): number {
  return m * 60_000;
}

export function secondsToMilliseconds(s: number): number {
  return s * 1_000;
}

export function isToday(date: Date | number | string): boolean {
  const today: Date = new Date();
  const toCheck: Date = new Date(date);
  return today.getTime() < toCheck.getTime() &&
    toCheck.getDate() === today.getDate() &&
    toCheck.getMonth() === today.getMonth() &&
    toCheck.getFullYear() === today.getFullYear();
}

export function isTodayWithoutTime(date: Date | number | string): boolean {
  const today: Date = new Date();
  const toCheck: Date = new Date(date);
  return toCheck.getDate() === today.getDate() &&
    toCheck.getMonth() === today.getMonth() &&
    toCheck.getFullYear() === today.getFullYear();
}

export function isTomorrowWithoutTime(date: Date | number | string): boolean {
  const tomorrow: Date = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const toCheck: Date = new Date(date);
  return toCheck.getDate() === tomorrow.getDate() &&
    toCheck.getMonth() === tomorrow.getMonth() &&
    toCheck.getFullYear() === tomorrow.getFullYear();
}

export function startOfDay(date: Date | number): Date {
  const end: Date = new Date(date);
  end.setHours(0, 0, 0, 0);
  return end;
}
