export function isSafari(userAgent: string): boolean {
  userAgent = userAgent.toLowerCase();
  return userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;
}

export function iOSVersion(userAgent: string): {
  major: null | number,
  minor: number | null,
  patch: number | null
} | null {
  if (!userAgent) return null;

  const matches: RegExpExecArray = /OS (\d\d?_\d(_\d)?)/.exec(userAgent);
  if (!matches) return null;

  const parts: number[] = matches[1].split('_').map((part: string): number => parseInt(part));

  return {
    major: parts[0],
    minor: parts[1],
    patch: parts[2] || 0,
  };
}
