import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ScpService } from '@scpc/modules/common/services/scp.service';
import { InboxBonusProgram, InboxMessage, InboxMessageUpdate } from '@scpc/dto';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { ScpWebSocketService } from '@scpc/modules/common/services/scp.websocket.service';
import { AuthenticationService } from '@scpc/modules/common/services/authentication.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class InboxService {

  private readonly count$: Subject<number> = new BehaviorSubject<number>(0);
  private readonly hasMessages$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private msg: InboxMessage[] = [];
  private readonly messages$: Subject<InboxMessage[]> = new BehaviorSubject<InboxMessage[]>(this.msg);
  private readonly bonuses: Set<string> = new Set<string>();

  constructor(private readonly scpService: ScpService,
              private readonly scpWebSocketService: ScpWebSocketService,
              private readonly authenticationService: AuthenticationService,
              @Inject(PLATFORM_ID) private readonly platformId: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.authenticationService.isAuthorized().subscribe((authorized: boolean) => this.subscribe(authorized));
      this.authenticationService.authorization.subscribe((authorized: boolean) => {
        this.subscribe(authorized);
      });
    }
  }

  public get hasMessages(): Observable<boolean> {
    return this.hasMessages$.asObservable();
  }

  public get messages(): Observable<InboxMessage[]> {
    return this.scpService.getInboxMessages().pipe(
      tap((messages: InboxMessage[]) => {
        this.count = messages.length;
        this.msg = messages;
        this.updateMessages();
        this.addSubscriptions();
      }),
      switchMap(() => this.messages$),
      finalize(() => this.removeSubscriptions()),
    );
  }

  public set count(count: number) {
    this.count$.next(count);
    this.hasMessages$.next(count > 0);
  }

  private static createBonusTopic(id: string): string {
    return 'inbox/bonuses/' + id;
  }

  private subscribe(authorized: boolean) {
    if (authorized) {
      this.scpWebSocketService.on('inbox').subscribe((count: string) => this.count = Number(count));
    } else {
      this.scpWebSocketService.off('inbox');
      this.removeSubscriptions();
    }
  }

  private addSubscriptions() {
    this.scpWebSocketService.subscribe('inbox/messages');
    this.scpWebSocketService
      .on('inbox/messages')
      .subscribe((update: InboxMessageUpdate) => this.processMessageUpdate(update));
    for (const message of this.msg) {
      this.processMessage(message);
    }
  }

  private processMessage(message: InboxMessage) {
    const id = message.bonusProgramOffer?.bonusProgram.bonusProgramId;
    if (id && !this.bonuses.has(id)) {
      this.bonuses.add(id);
      const topic = InboxService.createBonusTopic(id);
      this.scpWebSocketService.subscribe(topic);
      this.scpWebSocketService.on(topic).subscribe((update: InboxBonusProgram) => {
        const messages = this.msg.filter(item => item.bonusProgramOffer?.bonusProgram.bonusProgramId === update.bonusProgramId);
        if (messages.length) {
          messages.forEach(item => item.bonusProgramOffer.bonusProgram = update);
          this.updateMessages();
        }
      });
    }
  }

  private processMessageUpdate(update: InboxMessageUpdate) {
    for (let i = 0; i < this.msg.length; i++) {
      if (this.msg[i].id === update.message.id) {
        this.msg.splice(i, 1);
      }
    }
    if (update.status === 'ADDED') {
      this.msg.push(update.message);
    }
    this.updateMessages();
  }

  private updateMessages() {
    this.messages$.next(this.msg.sort((a: InboxMessage, b: InboxMessage) => a.availableAt - b.availableAt));
  }

  private removeSubscriptions() {
    this.scpWebSocketService.unsubscribe('inbox/messages');
    this.scpWebSocketService.off('inbox/messages');
    this.bonuses.forEach((id: string) => {
      const topic = InboxService.createBonusTopic(id);
      this.scpWebSocketService.unsubscribe(topic);
      this.scpWebSocketService.off(topic);
    });
    this.bonuses.clear();
  }

}
