import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StorageService } from '@scpc/modules/common/services';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  LuckyNumbersCombination,
  LuckyNumbersSelection,
  LuckyNumbersWager,
} from '@scpc/modules/lucky-numbers/dto/lucky-numbers.wager';
import { catchError, map, take } from 'rxjs/operators';
import { generateId, getCombinationsCount, toSelection } from '@scpc/modules/lucky-numbers/utils/lucky-numbers.utils';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { Market } from '@scpc/modules/lucky-numbers/dto/market';
import { Ball } from '@scpc/modules/lucky-numbers/dto/ball';
import { Outcome } from '@scpc/modules/lucky-numbers/dto/outcome';
import { CmsService } from '@scpc/modules/common/services/cms.service';
import { Bonus, Money, Selection, Wager } from '@scpc/dto';
import { isPlatformBrowser } from '@angular/common';
import { formatMoney, toCoins } from '@scpc/utils/money.utils';
import { TranslateService } from '@ngx-translate/core';
import { LuckyNumbersService } from '@scpc/modules/lucky-numbers/services/lucky-numbers.service';
import { Promotion, PromotionType } from '@scpc/dto/promotion';
import { GoogleTagManagerService } from '@scpc/modules/common/services/analytics/google-tag-manager.service';
import { LuckyNumbersSettings } from '@scpc/dto/lucky-numbers-settings';

export class RegisterWagersError {

  type: RegisterWagerErrorType;

  constructor(type: RegisterWagerErrorType) {
    this.type = type;
  }

}

export enum RegisterWagerErrorType {
  NOT_ENOUGH_MONEY = 'NOT_ENOUGH_MONEY',
  WALLET_NOT_ACTIVE = 'WALLET_NOT_ACTIVE',
  PROMOTION_NOT_AVAILABLE_AT_THIS_MOMENT = 'PROMOTION_NOT_AVAILABLE_AT_THIS_MOMENT',
  PROMOTION_NOT_AVAILABLE_ANY_MORE = 'PROMOTION_NOT_AVAILABLE_ANY_MORE',
  PROMOTION_HAS_CONCURRENT_BONUS_OFFER = 'PROMOTION_HAS_CONCURRENT_BONUS_OFFER',
}

@Injectable({ providedIn: 'root' })
export class LuckyNumbersCartService {
  private static readonly LS_LEY = 'ln.cart';

  public source: string | null = null;

  private readonly wagers: Subject<LuckyNumbersWager[]> = new BehaviorSubject<LuckyNumbersWager[]>([]);
  private readonly numberOfBets: Subject<number> = new BehaviorSubject<number>(0);
  private readonly discount: Subject<Money> = new BehaviorSubject<Money>({
    value: 0,
    currency: this.storageService.getCurrency(),
  });
  private readonly totalStake: Subject<Money> = new BehaviorSubject<Money>({
    value: 0,
    currency: this.storageService.getCurrency(),
  });
  private readonly toPay: Subject<Money> = new BehaviorSubject<Money>({
    value: 0,
    currency: this.storageService.getCurrency(),
  });
  private readonly totalToReturn: Subject<Money> = new BehaviorSubject<Money>({
    value: 0,
    currency: this.storageService.getCurrency(),
  });

  constructor(private readonly cmsService: CmsService,
              private readonly storageService: StorageService,
              private readonly luckyNumbersService: LuckyNumbersService,
              private readonly translateService: TranslateService,
              private readonly googleTagManagerService: GoogleTagManagerService,
              @Inject(PLATFORM_ID) private readonly platformId: string) {
    if (isPlatformBrowser(platformId)) {
      window.addEventListener('storage', /* istanbul ignore next */(data) => {
        if (data.key === LuckyNumbersCartService.LS_LEY) {
          this.recalculateTotals(JSON.parse(localStorage.getItem(LuckyNumbersCartService.LS_LEY) || '[]'));
        }
      });
      this.recalculateTotals(JSON.parse(localStorage.getItem(LuckyNumbersCartService.LS_LEY) || '[]'));
    }
  }

  private static hasErrors(wagers: LuckyNumbersWager[]): boolean {
    for (const wager of wagers) {
      if (wager.error) {
        return true;
      }
    }
    return false;
  }

  public async addWager(
    draw: Draw,
    market: Market,
    outcome: Outcome,
    balls: Ball[] = [],
    source?: string,
    stake?: Money,
    combinations?: LuckyNumbersCombination[],
  ): Promise<'ADDED' | 'REPLACED' | 'ALREADY_EXIST'> {
    const wagers = await this.getWagersAsync();
    const oldWager: LuckyNumbersWager | null =
      market.mainGridNumbers === 0 && market.secondaryGridNumbers === 0
        ? wagers.find((w) => w.selection.drawId === draw.drawId && w.selection.marketType === market.marketType)
        : null;
    if (oldWager && oldWager.selection.outcomeType === outcome.outcomeType) {
      return 'ALREADY_EXIST';
    } else if (oldWager && oldWager.selection.outcomeType !== outcome.outcomeType) {
      this.googleTagManagerService.removeFromLuckyNumbersCart([oldWager]);
      oldWager.selection = toSelection(draw, market, outcome, balls, combinations);
      oldWager.potentialReturn = {
        currency: oldWager.stake.currency,
        value: oldWager.stake.value * oldWager.selection.numberOfBets,
      };
      this.saveWagers(wagers);
      this.googleTagManagerService.addToLuckyNumbersCart([oldWager], null);
      return 'REPLACED';
    }
    const s: Money = stake ?? {
      currency: this.storageService.getCurrency(),
      value: await firstValueFrom(forkJoin([
        this.cmsService.getLuckyNumbersSettings(),
        this.storageService.customerId
          ? this.luckyNumbersService.getPreferences().pipe(map((v) => v), catchError(() => of({})))
          : of({}),
      ])).then((data: [LuckyNumbersSettings, { stake?: Money }]) => {
        if (data[0].useLastStakeAsDefault && data[1].stake) {
          return data[1].stake.value;
        }
        return data[0].defaultStake || Number(data[0].stakes.split(',')[0]);
      }),
    };
    const selection: LuckyNumbersSelection = toSelection(draw, market, outcome, balls, combinations);
    const wager: LuckyNumbersWager = {
      id: generateId(),
      selection: toSelection(draw, market, outcome, balls, combinations),
      stake: s,
      potentialReturn: {
        currency: s.currency,
        value: s.value * selection.numberOfBets * selection.odds.decimal,
      },
      wagerDate: new Date().getTime(),
      source,
    };
    this.saveWagers([...wagers, wager]);
    this.googleTagManagerService.addToLuckyNumbersCart([wager], draw);
    return 'ADDED';
  }

  public async updateBalls(wagerId: string, balls: Ball[], combinationId: string): Promise<void> {
    const wagers = await this.getWagersAsync();
    const wager: LuckyNumbersWager | null = wagers.find((w: LuckyNumbersWager): boolean => w.id === wagerId);
    this.googleTagManagerService.removeFromLuckyNumbersCart([wager]);
    if (wager.selection.balls.length) {
      wager.selection.balls = balls.map((b: Ball) => ({ type: b.type, value: b.value }));
      wager.selection.numberOfBets = getCombinationsCount({
        mainGridNumbers: wager.selection.mainGridNumbers,
        secondaryGridNumbers: wager.selection.secondaryGridNumbers,
      } as Market, balls);
      wager.potentialReturn = {
        currency: wager.stake.currency,
        value: wager.stake.value * wager.selection.numberOfBets * wager.selection.odds.decimal,
      };
    } else {
      for (const combination of wager.selection.combinations) {
        if (combination.id === combinationId) {
          combination.balls = balls;
          break;
        }
      }
    }
    this.saveWagers([...wagers]);
    this.googleTagManagerService.addToLuckyNumbersCart([wager], null);
  }

  public async addWagersWithPromotion(wagers: LuckyNumbersWager[], promotion: Promotion): Promise<void> {
    wagers.forEach((wager: LuckyNumbersWager) => {
      wager.promotionCode = promotion.code;
      wager.promotionId = promotion.id;
      wager.promotionName = promotion.name;
      wager.promotionDiscount = promotion.discount;
      wager.promotionSegment = promotion.type === PromotionType.SEGMENT;
      wager.selection.numberOfBets = wager.selection.balls?.length
        ? 1
        : wager.selection.combinations.length;
    });
    this.googleTagManagerService.removeFromLuckyNumbersCart(await this.getWagersAsync());
    this.saveWagers(wagers);
    this.googleTagManagerService.addToLuckyNumbersCart(wagers, {
      drawId: wagers[0].selection.drawId,
      drawName: wagers[0].selection.drawName,
    } as Draw);
  }

  public async updateWagerSubscription(wagerId: string, subscription: {
    times: number;
    changes: boolean;
  }): Promise<void> {
    const wagers = await this.getWagersAsync();
    const wager: LuckyNumbersWager | undefined = wagers.find((w) => w.id === wagerId);
    if (wager) {
      wager.subscription = subscription;
      this.saveWagers(wagers);
    }
  }

  public async updateWagerAmount(wagerId: string, stake: string): Promise<{
    stake: Money,
    potentialReturn: Money
  } | null> {
    const wagers = await this.getWagersAsync();
    const wager: LuckyNumbersWager | undefined = wagers.find((w) => w.id === wagerId);
    if (wager) {
      const currency: string = this.storageService.getCurrency();
      wager.stake = { currency, value: toCoins(isFinite(parseInt(stake, 10)) ? stake : '0', currency) };
      wager.potentialReturn = {
        currency,
        value: wager.stake.value * wager.selection.numberOfBets * wager.selection.odds.decimal,
      };
      this.saveWagers(wagers);
      return { stake: wager.stake, potentialReturn: wager.potentialReturn };
    }
    return null;
  }

  public async clearAll(analytics: boolean = true): Promise<void> {
    if (analytics) {
      this.googleTagManagerService.removeFromLuckyNumbersCart(await this.getWagersAsync());
    }
    this.saveWagers([]);
  }

  public async removeWager(wagerId: string): Promise<void> {
    const wagers = await this.getWagersAsync();
    this.saveWagers(wagers.filter((wager) => wager.id !== wagerId));
    this.googleTagManagerService.removeFromLuckyNumbersCart(wagers.filter((wager) => wager.id === wagerId));
  }

  public async reBet(wager: Wager): Promise<'ADDED' | 'REPLACED' | 'ALREADY_EXIST' | 'ERROR'> {
    return firstValueFrom(this.luckyNumbersService.getDraw(wager.selections[0].tournament, undefined, false, true, false).pipe(
      map(async (draw: Draw) => {
        try {
          const market: Market | undefined = draw.markets.find(m => m.marketType === wager.selections[0].marketType);

          if (!market) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('Market not found');
          }
          const outcome: Outcome | undefined = market.outcomes.find(o => o.outcomeType === wager.selections[0].outcomeType);

          if (!outcome) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('Outcome not found');
          }

          const balls = this.getBalls(wager);
          const numberOfBets = getCombinationsCount(market, balls);
          const isCombinations = numberOfBets === wager.selections.length;
          return this.addWager(draw, market, outcome, isCombinations ? this.getBalls(wager) : [], 'My bets page', {
            currency: wager.stake.currency,
            value: wager.stake.value / wager.selections.length,
          }, isCombinations ? [] : wager.selections.map((s: Selection): LuckyNumbersCombination => ({
            id: generateId(),
            balls: this.getBallsFromSelection(s),
          })));
        } catch (e) {
          return 'ERROR';
        }
      }),
      catchError((): Observable<'ERROR'> => of('ERROR')),
      take(1),
    ));
  }

  public getWagers(): Observable<LuckyNumbersWager[]> {
    return this.wagers;
  }

  public getTotalToReturn(): Observable<Money> {
    return this.totalToReturn;
  }

  public getTotalStake(): Observable<Money> {
    return this.totalStake;
  }

  public getToPay(): Observable<Money> {
    return this.toPay;
  }

  public getNumberOfBets(): Observable<number> {
    return this.numberOfBets;
  }

  public getDiscount(): Observable<Money> {
    return this.discount;
  }

  public async placeBets(): Promise<{
    status: 'SUCCESS' | RegisterWagersError | 'HAS_ERRORS' | 'UNKNOWN_ERROR',
    data?: {
      emailBonus?: Bonus
    }
  }> {
    const wagers: LuckyNumbersWager[] = (await this.getWagersAsync()).sort((a, b) => a.wagerDate - b.wagerDate);

    this.recalculateTotals(wagers);

    if (LuckyNumbersCartService.hasErrors(wagers)) {
      return { status: 'HAS_ERRORS' };
    }

    const toPlaceBetsWafers = wagers.map(wager => {
      const w = Object.assign({}, wager);
      w.selection = Object.assign({}, wager.selection);
      delete w.selection.country;
      delete w.selection.country;
      delete w.selection.drawType;
      delete w.selection.marketCategory;
      delete w.selection.maxPayout;
      delete w.selection.numberOfBets;
      delete w.selection.shortDrawName;
      delete w.pending;
      delete w.potentialReturn;
      delete w.wagerDate;
      delete w.canBeCancelled;
      delete w.error;
      delete w.isInvalidStake;
      delete w.isInvalidDraw;
      delete w.source;
      return w;
    });

    return firstValueFrom(this.luckyNumbersService.registerWagers(toPlaceBetsWafers).pipe(
      map((data: {
        emailBonus?: {
          bonusId: string,
          name: string
        }
        wagersIds: { [key: string]: string; },
        subscriptionsIds: { [key: string]: string; }
      }): {
        status: 'SUCCESS',
        data: {
          emailBonus?: Bonus,
        }
      } => {
        this.googleTagManagerService.purchaseLuckyNumbersWagers(wagers.map((wager: LuckyNumbersWager) => {
          const id = wager.id;
          wager.id = data.wagersIds[id];
          wager.subscriptionId = (data.subscriptionsIds || {})[id];
          return wager;
        }));
        return { status: 'SUCCESS', data: { emailBonus: data.emailBonus } };
      }),
      catchError(response => of(this.processErrorResponse(wagers, response as unknown))),
      take(1),
    ));
  }

  private processErrorResponse(wagers: LuckyNumbersWager[],
                               response: {
                                 error?: {
                                   code: number,
                                   data?: {
                                     details?: {
                                       wagerId: string,
                                       selectionId: string,
                                       combinationId: string,
                                       error: string,
                                       value: any
                                     }[]
                                   }
                                 }
                               }): { status: RegisterWagersError | 'HAS_ERRORS' | 'UNKNOWN_ERROR' } {
    let isUnknownError = true;
    if (response.error.code === 15012 && response.error.data?.details) {
      for (const error of response.error.data.details) {
        if (Object.values(RegisterWagerErrorType).includes(error.error as any)) {
          return { status: new RegisterWagersError(error.error as RegisterWagerErrorType) };
        }
        const wager: LuckyNumbersWager | undefined = wagers.find(w => w.id === error.wagerId);
        if (wager) {
          switch (error.error) {
            case  'DRAW_HAS_BEEN_STARTED':
            case  'INSTANT_DRAW_HAS_BEEN_STARTED':
              isUnknownError = false;
              wager.error = this.createError('DRAW_HAS_BEEN_STARTED', 'LN.ERROR.DRAW_HAS_BEEN_STARTED', 0);
              wager.isInvalidDraw = true;
              break;
            case  'INVALID_STAKE':
              isUnknownError = false;
              wager.error = this.createError('STAKE_IS_UNDEFINED', 'LN.ERROR.INVALID_STAKE', 0);
              wager.isInvalidStake = true;
              break;
            case  'INVALID_STAKE_ROUNDING':
              isUnknownError = false;
              wager.error = this.createError('STAKE_ROUNDING', 'LN.ERROR.INVALID_STAKE_ROUNDING', 0);
              wager.isInvalidStake = true;
              break;
            case  'MAX_PAYOUT_EXCEEDED':
              isUnknownError = false;
              wager.error = this.createError('MAX_PAYOUT_EXCEEDED', 'LN.ERROR.MAX_PAYOUT_EXCEEDED', error.value.value);
              wager.isInvalidStake = true;
              break;
            case  'MAX_STAKE_EXCEEDED':
              isUnknownError = false;
              wager.error = this.createError('MAX_STAKE_EXCEEDED', 'LN.ERROR.MAX_STAKE_EXCEEDED', error.value.value);
              wager.isInvalidStake = true;
              break;
          }
        }
      }
    }
    return { status: isUnknownError ? 'UNKNOWN_ERROR' : 'HAS_ERRORS' };
  }

  private createError(type: string, message, amount: number): { type: string, message: string } {
    return {
      type,
      message: this.translateService.instant(message, {
        amount: formatMoney({ value: amount, currency: this.storageService.getCurrency() }),
      }),
    };
  }

  private async getWagersAsync(): Promise<LuckyNumbersWager[]> {
    return firstValueFrom(this.wagers);
  }

  private saveWagers(wagers: LuckyNumbersWager[]): void {
    localStorage.setItem(LuckyNumbersCartService.LS_LEY, JSON.stringify(wagers));
    this.recalculateTotals(wagers);
  }

  private recalculateTotals(wagers: LuckyNumbersWager[]): void {
    let numberOfBets = 0;
    let discount = 0;
    let totalStake = 0;
    let totalReturn = 0;
    const promotions: Set<number> = new Set<number>();
    const now = new Date().getTime();
    for (const wager of wagers) {
      wager.error = undefined;
      wager.isInvalidStake = false;
      wager.isInvalidDraw = false;
      if (wager.selection.drawDate <= now) {
        wager.error = this.createError('DRAW_HAS_BEEN_STARTED', 'LN.ERROR.DRAW_HAS_BEEN_STARTED', 0);
        wager.isInvalidDraw = true;
      } else if (!wager.stake?.value) {
        wager.error = this.createError('STAKE_IS_UNDEFINED', 'LN.ERROR.STAKE_IS_UNDEFINED', 0);
        wager.isInvalidStake = true;
      } else if (wager.selection.maxPayout && wager.stake.value * wager.selection.odds.decimal > wager.selection.maxPayout) {
        wager.error = this.createError('MAX_PAYOUT_EXCEEDED', 'LN.ERROR.MAX_PAYOUT_EXCEEDED', wager.selection.maxPayout);
        wager.isInvalidStake = true;
      }
      numberOfBets += wager.selection.numberOfBets * (wager.subscription?.times || 1);
      totalStake += (wager.stake?.value || 0) * wager.selection.numberOfBets * (wager.subscription?.times || 1);
      totalReturn += (wager.stake?.value || 0) * wager.selection.odds.decimal * wager.selection.numberOfBets * (wager.subscription?.times || 1);
      if (wager.promotionId && !promotions.has(wager.promotionId)) {
        discount += wager.promotionDiscount.value;
        promotions.add(wager.promotionId);
      }
    }
    const currency = this.storageService.getCurrency();
    this.wagers.next(wagers);
    this.numberOfBets.next(numberOfBets);
    this.discount.next({ currency, value: discount });
    this.totalStake.next({ currency, value: totalStake });
    this.toPay.next({ currency, value: totalStake - discount });
    this.totalToReturn.next({ currency, value: totalReturn });
  }

  private getBalls(wager: Wager): Ball[] {
    const regularBalls: Set<string> = new Set<string>();
    const bonusBalls: Set<string> = new Set<string>();
    for (const selection of wager.selections) {
      if (!selection.outcomeDescription) {
        break;
      }
      const regular = selection.outcomeDescription.match(/NORMAL:\d+/g) || [];
      const bonus = selection.outcomeDescription.match(/BONUS:\d+/g) || [];
      for (const r of regular) {
        regularBalls.add(r);
      }
      for (const b of bonus) {
        bonusBalls.add(b);
      }
    }

    return [...this.convertToBalls(regularBalls, 'NORMAL'), ...this.convertToBalls(bonusBalls, 'BONUS')];
  }

  private getBallsFromSelection(selection: Selection) {
    const regularBalls: Set<string> = new Set<string>();
    const bonusBalls: Set<string> = new Set<string>();
    const regular = selection.outcomeDescription.match(/NORMAL:\d+/g) || [];
    const bonus = selection.outcomeDescription.match(/BONUS:\d+/g) || [];
    for (const r of regular) {
      regularBalls.add(r);
    }
    for (const b of bonus) {
      bonusBalls.add(b);
    }
    return [...this.convertToBalls(regularBalls, 'NORMAL', false), ...this.convertToBalls(bonusBalls, 'BONUS', false)];
  }

  private convertToBalls(balls: Set<string>, type: string, selected: boolean = true): Ball[] {
    return [...balls].map(b => Number(b.replace(type + ':', ''))).sort().map(b => ({ value: b, type, selected }));
  }

}
