export async function getVisitorId(data: {
  fingerprintEnabled: boolean;
  fingerprintToken: string | undefined;
  fingerprintDomain: string | undefined;
}): Promise<string | undefined> {
  if (!data.fingerprintEnabled) {
    return undefined;
  }
  const fp = data.fingerprintToken
    ? await import('@fingerprintjs/fingerprintjs-pro').then((module) =>
      module.default.load({
        token: data.fingerprintToken,
        endpoint: data.fingerprintDomain,
        region: 'eu',
      }),
    )
    : await import('@fingerprintjs/fingerprintjs').then((module) => module.default.load());
  const result = await fp.get();
  return result.visitorId;
}
