@if (seo && !loading) {
  <div class='seo-block' #seoBlock (onResize)='height = seoContent.scrollHeight'>
    <div [innerHTML]='seo' class='seo' scpRouteTransformer #seoContent></div>
    <div class='shadow' [hidden]='height < 195'>
      <button (click)='updateSeoState(seoBlock)' [attr.aria-label]="'BUTTON.MORE_RESULTS' | translate">
        <img src='/assets/images/svg/menu-arrow.svg' alt='' height='8' width='13' loading='lazy' />
      </button>
    </div>
  </div>
}
