export function formatLeftTime(end: number): string {
  const now: number = Date.now();
  const diff = end - now;
  const days = Math.floor(diff / 86400000);
  if (days > 0) {
    return days + (days > 1 ? ' days' : ' day');
  } else {
    const hours = Math.floor(diff / 3600000);
    if (hours > 0) {
      return hours + (hours > 1 ? ' hours' : ' hour');
    } else {
      const min = Math.floor(diff / 60000);
      if (min > 0) {
        return min + ' min';
      }
    }
  }
  return '';
}

export function addHours(date: Date, hours: number): Date {
  return new Date(date.getTime() + hours * 60 * 60 * 1000);
}
