import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthenticationService, StorageService } from '@scpc/modules/common/services';
import { DepositType, Money } from '@scpc/dto';
import { makeStateKey, StateKey } from '@angular/core';
import { REDIRECTS_301 } from '@scpc/redirects';

export const DEPOSIT_RESULT: StateKey<{
  type: DepositType;
  amount?: Money;
  status?: string;
  reference?: string;
}> = makeStateKey('deposit-result');

@Injectable({
  providedIn: 'root',
})
export class ActivationGuard implements CanActivate {

  private redirects: Map<string, string> = new Map(REDIRECTS_301);

  constructor(private readonly storageService: StorageService,
              private readonly authenticationService: AuthenticationService,
              private readonly router: Router) {
  }

  public async canActivate(next: ActivatedRouteSnapshot,
                           state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let needToRemoveParams = false;

    if (state.root.queryParams.promotion && !this.storageService.isAuthorised()) {
      this.authenticationService.setInterruptedUrl(state.url);
      return this.router.parseUrl('/sign-in');
    }

    if (state.root.queryParams.sub8) {
      this.storageService.device = state.root.queryParams.sub8;
    } else if (state.root.queryParams.device) {
      this.storageService.device = state.root.queryParams.device;
    }

    if (state.root.queryParams.accessToken && state.root.queryParams.refreshToken && !this.storageService.isAuthorised()) {
      try {
        this.storageService.saveAccessToken({
          // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
          access_token: state.root.queryParams.accessToken,
          // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
          refresh_token: state.root.queryParams.refreshToken,
          // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
          token_type: 'Bearer',
        });
      } catch (e) { /* empty */
      }
      needToRemoveParams = true;
    }

    if (state.root.queryParams.depositType) {
      return this.router.navigate(['/deposits/process'], { queryParamsHandling: 'preserve' });
    }

    const url: string = state.url.split('?')[0].toLowerCase();

    if (this.redirects.has(url)) {
      return this.router.navigate([this.redirects.get(url)], { queryParamsHandling: 'preserve' });
    }

    if (needToRemoveParams) {
      return this.router.createUrlTree([], {
        queryParams: { accessToken: null, refreshToken: null, promotion: null, device: null },
        queryParamsHandling: 'merge',
      });
    }

    return true;
  }

}
