import { Swiper } from 'swiper';
import { ChangeDetectorRef, DestroyRef, Directive, inject, NgZone, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';
import { isPlatformBrowser } from '@angular/common';

@Directive()
export abstract class AbstractSwiperComponent {

  protected beginning: Subject<boolean> = new BehaviorSubject(true);
  protected end: Subject<boolean> = new BehaviorSubject(true);
  protected index: Subject<number> = new BehaviorSubject(0);
  protected swiper: Swiper;
  protected changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected zone: NgZone = inject(NgZone);
  protected idle: Idle = inject(Idle);
  protected isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));
  protected destroyRef: DestroyRef = inject(DestroyRef);

  protected on = {
    afterInit: /* istanbul ignore next */ (swiper: Swiper): void => this.updateSlider(swiper),
    reachBeginning: /* istanbul ignore next */ (swiper: Swiper): void => this.updateSlider(swiper),
    reachEnd: /* istanbul ignore next */ (swiper: Swiper): void => this.updateSlider(swiper),
    resize: /* istanbul ignore next */ (swiper: Swiper): void => {
      this.updateSlider(swiper);
      if (swiper.isLocked) {
        swiper.slideTo(0, 0);
      }
    },
    setTranslate:  /* istanbul ignore next */(swiper: Swiper, translate: number): void => {
      if (!Number.isNaN(translate)) {
        this.updateSlider(swiper);
      }
    },
    slideChange: (swiper: Swiper): void => {
      this.index.next(swiper.realIndex);
    },
  };

  protected updateSlider(swiper: Swiper): void {
    this.beginning.next(swiper.isBeginning || swiper.isLocked);
    this.end.next(swiper.isEnd || swiper.isLocked);
    this.zone.run(() => this.changeDetectorRef.detectChanges());
  }

  public isBeginning(): Observable<boolean> {
    return this.beginning.asObservable();
  }

  public isEnd(): Observable<boolean> {
    return this.end.asObservable();
  }

  public prev(): void {
    this.swiper.slidePrev();
  }

  public next(): void {
    this.swiper.slideNext();
  }

}
