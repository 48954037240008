import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ConfigService, MaintenanceData } from '@scpc/modules/common/services/config.service';
import { firstValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterService } from '@scpc/modules/common/services';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {

  constructor(private readonly router: Router,
              private readonly configService: ConfigService,
              private readonly routerService: RouterService) {
  }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const path = route.routeConfig.path;
    return firstValueFrom(of(this.configService.getLuckyNumbersMaintenanceData())
      .pipe(map((data: MaintenanceData) => {
        if (this.routerService.currentUrl.startsWith('/maintenance') && data) {
          return false;
        } else if (path === 'maintenance' && !data) {
          this.router.navigateByUrl('/');
          return false;
        } else if (path !== 'maintenance' && data) {
          this.router.navigateByUrl('/maintenance');
          return false;
        }
        return true;
      })));
  }

}
