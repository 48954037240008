import { LuckyNumbersCombination, LuckyNumbersSelection } from '@scpc/modules/lucky-numbers/dto/lucky-numbers.wager';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { Market } from '@scpc/modules/lucky-numbers/dto/market';
import { Outcome } from '@scpc/modules/lucky-numbers/dto/outcome';
import { Ball } from '@scpc/modules/lucky-numbers/dto/ball';

export function toSelection(draw: Draw,
                            market: Market,
                            outcome: Outcome,
                            balls: Ball[],
                            c: LuckyNumbersCombination[]): LuckyNumbersSelection {
  return {
    id: generateId(),
    drawId: draw.drawId,
    drawNumber: draw.drawNumber,
    drawDate: draw.drawDate,
    gameNumber: draw.gameNumber,
    drawName: draw.drawName,
    drawType: draw.type,
    marketType: market.marketType,
    marketCategory: market.category,
    mainGridNumbers: market.mainGridNumbers,
    secondaryGridNumbers: market.secondaryGridNumbers,
    outcomeType: outcome.outcomeType,
    odds: outcome.odds,
    numberOfBets: c?.length ? c.length : getCombinationsCount(market, balls),
    balls: c?.length ? [] : balls?.map((b) => ({ type: b.type, value: b.value })) || [],
    country: draw.country,
    shortDrawName: draw.shortDrawName,
    maxPayout: draw.maxPayout,
    combinations: c
  };
}

export function ballsToString(balls: Ball[]) {
  if (!balls) {
    return '';
  }
  return balls.map((b: Ball): string => b.type[0] + b.value).join(',');
}

export function generateId(): string {
  return [...Array(20)].map(() => Math.random().toString(20)[2]).join('');
}

export function getCombinationsCount(market: Market, balls: Ball[]): number {
  if (!balls?.length) {
    return 1;
  }
  const regularCount: number = balls.filter(b => b.type === 'NORMAL').length;
  const bonusCount: number = balls.length - regularCount;
  if (market.mainGridNumbers > 0 && market.secondaryGridNumbers > 0) {
    return combinations(regularCount, market.mainGridNumbers) * combinations(bonusCount, market.secondaryGridNumbers);
  } else if (market.mainGridNumbers > 0) {
    return combinations(regularCount, market.mainGridNumbers);
  } else {
    return combinations(bonusCount, market.secondaryGridNumbers);
  }
}

export function createBalls(count: number, type: 'NORMAL' | 'BONUS'): Ball[] {
  return Array.from(Array(count).keys()).map((index) => ({ value: index + 1, type }));
}

export function getBallIndex(balls: Ball[], ball: Ball): number {
  return balls.findIndex((b: Ball): boolean => b.value === ball.value && b.type === ball.type);
}

function range(a, b) {
  let prd = a;
  let i = a;

  while (i++ < b) {
    prd *= i;
  }
  return prd;
}

function combinations(n, r) {
  if (n <= r) {
    return 1;
  } else {
    r = (r < n - r) ? n - r : r;
    return range(r + 1, n) / range(1, n - r);
  }
}
