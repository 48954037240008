import { StorageService } from '@scpc/modules/common/services/storage.service';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { AuthenticationService } from '@scpc/modules/common/services/authentication.service';
import { isPlatformBrowser } from '@angular/common';
import { Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import type { Socket } from 'socket.io-client';
import { filter, map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScpWebSocketService {

  private socket: Socket | null = null;
  private subscriptions = new Map();
  private topics: Map<string, Subject<unknown>> = new Map<string, Subject<unknown>>();
  private connecting: boolean = false;
  private isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    private readonly storageService: StorageService,
    private readonly authenticationService: AuthenticationService,
    private readonly configService: ConfigService,
    private readonly zone: NgZone,
  ) {
    if (this.isBrowser) {
      this.connect();
    }
    this.authenticationService.authorization.subscribe((isAuthorized: boolean): void => {
      if (isAuthorized) {
        this.connect(true);
      } else if (this.socket) {
        this.socket.emit('sign-out');
      }
    });
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.subscriptions.clear();
    }
    this.connecting = false;
  }

  public on(topic: string): Observable<unknown> {
    this.connect();
    /* istanbul ignore else */
    if (this.socket) {
      return new Observable((observer): void => {
        this.socket.on(topic, (value: unknown): void => {
          observer.next(value);
        });
      });
    } else {
      const o = new Subject();
      this.topics.set(topic, o);
      return o;
    }
  }

  public off(topic: string): void {
    if (this.socket) {
      this.socket.off(topic);
    }
  }

  public onWithCallback(event: string, callback: (value: unknown) => void): void {
    if (this.isBrowser) {
      this.connect().then(() => this.socket.on(event, callback));
    }
  }

  public offWithCallback(event: string, callback: (value: unknown) => void): void {
    this.socket?.off(event, callback);
  }

  public subscribe(topic: string): boolean {
    this.connect();
    if (this.subscriptions.has(topic)) {
      return false;
    }
    this.subscriptions.set(topic, topic);
    /* istanbul ignore next */
    if (this.socket?.connected) {
      this.socket.emit('subscribe', topic);
    }
    return true;
  }

  public unsubscribe(topic: string): void {
    if (this.socket) {
      this.subscriptions.delete(topic);
      try {
        this.socket.emit('unsubscribe', topic);
      } catch (_) { /* empty */
      }
    }
  }

  private async connect(signIn: boolean = false): Promise<void> {
    /* istanbul ignore else */
    if (!this.connecting && !this.socket && this.isBrowser) {
      this.connecting = true;
      await firstValueFrom(this.configService.initialize.pipe(
        filter((value: boolean) => value),
        take(1),
        map(async (): Promise<void> => {
          await this.zone.runOutsideAngular(async () => {
            this.socket = (window['io'] || /* istanbul ignore next */ (await import('socket.io-client')).io)(window.location.origin, {
              path: '/websockets/api/1.0',
              transports: ['websocket', 'polling'],
              query: { siteId: this.configService.siteId, operationId: this.configService.operationId },
            });
            this.socket.on('connect', () => {
              this.connecting = false;
              if (this.storageService.accessToken) {
                this.socket.emit('sign-in', this.storageService.accessToken);
              }
              if (this.subscriptions.size) {
                for (const topic of this.subscriptions.values()) {
                  this.socket.emit('subscribe', topic);
                }
              }
              /* istanbul ignore next */
              for (const entry of this.topics) {
                this.socket.on(entry[0], (value: unknown): void => entry[1].next(value));
              }
            });
          });
        }),
      ));
    } else if (signIn) {
      this.socket.emit('sign-in', this.storageService.accessToken);
    }
  }

}
