import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { interval, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';

@Component({
  selector: 'scp-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ClockComponent implements OnInit, OnDestroy {

  @ViewChild('clock', { static: true, read: ElementRef })
  private clock: ElementRef<HTMLDivElement>;

  private readonly destroy: Subject<void> = new Subject<void>();
  private readonly formatter = new DatePipe(this.localeId);

  constructor(
    private readonly zone: NgZone,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(LOCALE_ID) private readonly localeId: string,
    private readonly idle: Idle,
  ) {
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.idle.requestIdleCallback((): void => {
        this.zone.runOutsideAngular((): void => {
          interval(1000).pipe(
            startWith(this.currentTime()),
            takeUntil(this.destroy),
            map(() => this.currentTime()),
          ).subscribe((value: string): string => this.clock.nativeElement.textContent = value);
        });
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private currentTime(): string {
    return this.formatter.transform(new Date(), 'dd-MM-yyyy HH:mm:ss');
  }

}
