<div class='scp-bookmakers__close-row'>
  <button (click)='close()'>
    <img src='assets/images/svg/x-3.svg' alt=''>
  </button>
</div>
<div class='scp-bookmakers'>
  <div class='scp-bookmakers__content_row'>
    <div>
      <div class='scp-bookmakers__content_row__full-padding'>
        <div class='scp-bookmakers__content_row__notification'>
          Unfortunately {{ siteName }} does not operate in {{ country.name }}, but we found top-tier
          sportsbooks
        </div>
      </div>
      <div class='scp-bookmakers__content_row__title'>
        Sportsbooks you can access from {{ country.name }}:
      </div>
    </div>
    <div class='swiper'>
      <div class='swiper-wrapper'>
        @for (bookmaker of bookmakers; track $index) {
          <div class='swiper-slide'>
            <div class='scp-bookmakers__content_row__padding'>
              <img class='scp-bookmakers__content_row__logo'
                   alt=''
                   [src]='bookmaker.logo.url'
                   [style.aspect-ratio]='bookmaker.logo.width/bookmaker.logo.height'
                   width='100%'>
            </div>
            <div class='scp-bookmakers__content_row__padding'>
              <div class='scp-bookmakers__content_row__offer'>{{ bookmaker.offer }}</div>
            </div>
            <div class='scp-bookmakers__content_row__padding'>
              <div class='scp-bookmakers__content_row__benefits'>
                <img src='/assets/images/svg/benefict.svg' width='16' height='16' alt=''>
                <div>{{ bookmaker.advantage1 }}</div>
                <img src='/assets/images/svg/benefict.svg' width='16' height='16' alt=''>
                <div>{{ bookmaker.advantage2 }}</div>
                <img src='/assets/images/svg/benefict.svg' width='16' height='16' alt=''>
                <div>{{ bookmaker.advantage3 }}</div>
              </div>
            </div>
            @if (bookmakers.length > 1) {
              <div class='scp-bookmakers__content_row__padding'>
                <div class='scp-bookmakers__content_row__scroll'></div>
              </div>
            }
            <div class='scp-bookmakers__content_row__full-padding'>
              <scp-btn type='1'
                       (click)='selected(bookmaker)'
                       [text]='bookmaker.button'
                       [height]='54'
                       [hasMarginTop]='false'>
              </scp-btn>
            </div>
          </div>
        }
      </div>
      @if (bookmakers.length > 1) {
        <div class='scp-bookmakers__content_row__navigation'>
          <div class='scp-bookmakers__content_row__padding'>
            <div class='scp-bookmakers__content_row__scroll'>
              <button (click)='swiper.slidePrev()'>
                <img src='assets/images/svg/arrow-4.svg' alt=''>
              </button>
              <div class='scp-bookmakers__content_row__quick_navigation'>
                @for (bookmaker of bookmakers; track $index) {
                  <button [class.active]='(index| async) === $index' (click)='swiper.slideTo($index)'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'>
                      <circle cx='3' cy='3' r='3' fill-opacity='0.5' />
                    </svg>
                  </button>
                }
              </div>
              <button (click)='swiper.slideNext()'>
                <img src='assets/images/svg/arrow-4.svg' alt=''>
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
