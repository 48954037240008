import { Injectable } from '@angular/core';
import { SportsCartService } from '@scpc/modules/cart/services/sports-cart.service';
import { firstValueFrom } from 'rxjs';
import { LuckyNumbersCartService } from '@scpc/modules/cart/services/lucky-numbers-cart.service';
import { CartDialogService } from '@scpc/modules/cart/services/cart.dialog.service';
import { AuthenticationService } from '@scpc/modules/common/services';

@Injectable({ providedIn: 'root' })
export class CommonCartService {

  constructor(private readonly sportsCartService: SportsCartService,
              private readonly luckyNumbersService: LuckyNumbersCartService,
              private readonly cartDialogService: CartDialogService,
              private readonly authenticationService: AuthenticationService) {
  }

  public async isBetAllowed(product: 'SPORT' | 'LUCKY_NUMBERS'): Promise<boolean> {
    if (product === 'SPORT' && (await firstValueFrom(this.luckyNumbersService.getNumberOfBets(), { defaultValue: 0 })) > 0) {
      await this.showCart(product);
      return false;
    } else if (product === 'LUCKY_NUMBERS' && (await firstValueFrom(this.sportsCartService.getNumberOfBets(), { defaultValue: 0 })) > 0) {
      await this.showCart(product);
      return false;
    }
    return true;
  }

  private async showCart(product: 'SPORT' | 'LUCKY_NUMBERS'): Promise<void> {
    const isAuthorized: boolean = await firstValueFrom(this.authenticationService.isAuthorized());
    (await this.cartDialogService.open({
      isAuthorized,
      product: 'SPORT',
      clear: true
    })).closed
      .subscribe(async (data: { clear?: boolean }): Promise<void> => {
        if (data?.clear) {
          if ('LUCKY_NUMBERS' === product) {
            await this.sportsCartService.clearAll();
          } else if ('SPORT' === product) {
            await this.luckyNumbersService.clearAll(true);
          }
        }
      });
  }

}
