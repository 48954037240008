export enum BankVerificationMode {
  AVS = 'AVS',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Documents = 'Documents'
}

export interface BankVerification {

  mode: BankVerificationMode;

}
