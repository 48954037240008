import { StorageService } from '@scpc/modules/common/services';
import { Inject, Injectable, Optional } from '@angular/core';
import { addHours } from '@scpc/modules/promotions/utils/date';
import { SmartBannerConfig } from '@scpc/dto';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SmartBannerService {

  private readonly IPHONE: boolean;
  private readonly ANDROID: boolean;
  private readonly HUAWEI: boolean;
  private readonly SAMSUNG: boolean;

  private allowed = null;
  private platform = null;

  constructor(private readonly storageService: StorageService,
              @Optional() @Inject('USER_AGENT')
              private readonly userAgent: string,
              @Inject(DOCUMENT) private readonly document: Document) {
    const ua: string = this.userAgent || this.document.defaultView.navigator.userAgent;
    this.IPHONE = /\biPhone\b/.test(ua);
    this.ANDROID = /\bAndroid\b/.test(ua);
    this.HUAWEI = /huawei/i.test(ua);
    this.SAMSUNG = /\bSamsung\b|SM-|GT-|SGH-|BGT-|SCH-|SCS-|SHW-|SPH-|SWC-|SHV-/.test(ua);
  }

  public updateCloseTime(): void {
    this.storageService.smartBanner = new Date();
  }

  public isAllowed(config: SmartBannerConfig, isAuthorized: boolean): boolean {
    if (!config?.enabled) {
      return false;
    }
    const date: Date | null = this.storageService.smartBanner;
    if (!date) {
      return true;
    }
    return isAuthorized ? new Date().getTime() >= addHours(date, config.hidePeriod * 24).getTime() : true;
  }

  public isSupported(): boolean {
    if (this.allowed === null) {
      const isNativeApp = this.storageService.device === 'ios' || this.storageService.device === 'android';
      if (isNativeApp) {
        this.allowed = false;
      } else {
        this.allowed = this.ANDROID || this.IPHONE;
      }
    }
    return this.allowed;
  }

  public getPlatform(): 'IPHONE' | 'ANDROID' | 'SAMSUNG' | 'HUAWEI' | 'UNKNOWN' {
    if (!this.platform) {
      if (this.IPHONE) {
        this.platform = 'IPHONE';
      } else if (this.HUAWEI) {
        this.platform = 'HUAWEI';
      } else if (this.SAMSUNG) {
        this.platform = 'SAMSUNG';
      } else if (this.ANDROID) {
        this.platform = 'ANDROID';
      } else {
        this.platform = 'UNKNOWN';
      }
    }
    return this.platform;
  }

}
